#app {
    #controls,
    #content {
        padding: 24px;
        text-align: center;
        background: #f7fafc;
    }
    #controls {
        background: #F5F5F5;
    }
    #motto,
    #details,
    #interview {
        padding-bottom: 84px;
    }
    .edit-button {
        & {
            font-weight: 900;
            text-align: right;
            text-transform: uppercase;
        }
    }
    .container {
        width: 100%;
        position: relative;
    }
    @media (min-width: 992px) {
        .container {
            width: 600px;
            position: relative;
        }
    }
    .feature-lock-indicator {
      color: #CAD1D9;
      position: relative;

      &::before {
        color: #E30074;
        margin-right: 4px;
        content: "\F033E";
        display: inline-block;
        font: normal normal normal 24px/1 "Material Design Icons";
        font-size: inherit;
        text-rendering: auto;
        line-height: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    .disable-vertical-scroll {
        overflow-y: hidden;
    }
}
