@import "../../../proxima";

#register-new, #legal-new {
  .btn {
    @include proxima-font-semibold();
    text-transform: uppercase;
    height: 47px;
    line-height: 47px;
    box-shadow: none;

    @media screen and (min-width: 375px) {
      font-size: 16px;
      min-width: 128px;
    }

    &-primary {
      color: white;
      border-radius: 10px;
      background: linear-gradient(0.38turn, #DC0064, #FF0073);
    }

    &-black {
      color: white;
      border-radius: 10px;
      background: #2F3740;
    }

    &-secondary {
      color: #2F3740;
      text-decoration: underline;
      padding: 0;
      border: none;
      min-width: unset;
    }

    &-blocked {
      width: 100%;

      @media screen and (min-width: 992px) {
        min-width: 464px;
      }
    }

    &-verify {
      width: 100%;

      @media screen and (min-width: 992px), (min-height: 1024px) and (orientation: portrait) {
        max-width: 464px;
        margin: 0 auto;
      }
    }

    &:disabled {
      background: #CED6E0 !important;
      color: #FFFFFF !important;
      cursor: not-allowed;
    }

    &__overview {
      font-size: 16px;
      height: 47px;

      @media screen and (min-width: 992px) {
        font-size: 18px;
        height: 60px;
      }
    }
  }
}
