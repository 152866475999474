#register {
    background: #FFFFFF;

    #content
    {
        background: #ffffff;
    }

    #start {
        #content {
            padding-top: 21px;
        }
    }
    #photos {
      .fixed-action-btn {
        bottom: 30px;

        &.absolute-pos {
          position: absolute;
        }
      }
    }
    #complete {
        padding-top: 53px;

        .explanation-container {
            border-radius: 8px;
            background: #F5F7FA;
            font-size: 0.875rem;
            color: #757575;
            padding: 24px;
            max-width: 312px;
            margin: 0 auto;
        }

        .explanation-title { 
           color: #2E353D;
           font-size: 2rem; 
           padding-top: 48px;
           padding-bottom: 48px;
           font-weight: 600;
        }

        .btn-box-shadow {
            box-shadow: 0px 2px 2px #0000003D
        }
    }
    @media screen and (min-width: 1024px) {
      #photos {
        .fixed-action-btn {
          bottom: 40px !important;
          right: 15px;
        }
        .navigator {
          left: 0 !important;
          bottom: 15px !important;
          position: fixed !important;
        }
      }
      #complete {
        .explanation-container {
            max-width: 400px;
            margin: 0 auto;
        }
      }
    }
    & {
      padding: 24px 24px 90px;
      height: 100vh;

      @media (min-width: 361px) {
        padding: 32px 32px 90px;
      }
    }
    .autocomplete-parent .autocomplete-content.dropdown-content {
        max-height: 300px;
    }
    .autocomplete-parent .autocomplete-content.dropdown-content .highlight {
        font-weight: bold;
    }
    .md-autocomplete-dropdown {
        top: 0 !important;
        height: 100% !important;
        width: 100% !important;
        margin: 0 !important;
    }
    #header {
        @include proxima-font-semibold();

        line-height: normal;
        margin: 0;
        padding: 40px 0;
        font-size: 2.75rem;
        text-align: center;
        text-transform: capitalize;
    }
    #bra-cup {
        .switch-container {
            color: $krypton-grey-darken;

            div.switch-pill > span:first-child {
              border-right: 1px solid #9E9E9E;
            }
        }
    }
    #name p small {
        color: #A2A2A2;
    }
    .step {
        color: $krypton-grey-darken;
    }
    .agreement-btns {
        position: fixed;
        width: 100%;
        bottom: 30px;
        left: 0;
        right: 0;
        padding: 0 30px;
        margin: auto;

        @media (min-width: 992px) {
            position: fixed;
            width: 600px;
        }
    }
    #content {
        padding: 0;
    }
    .content {
        color: $krypton-grey-darken;
    }
    #register-preview {
        margin: -32px auto;
    }
    #start {
        #header {
            font-family: 'Proxima Nova Semibold';
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            & {
                font-size: 2.5rem;
            }
            @media (min-width: 321px) {
                font-size: 2.75rem;
            }
        }
    }
    #rates,
    #potential {
        .sample-rate {
            @include proxima-font-semibold();
            font-size: 3rem;
        }
        .blur {
            position: relative;
            filter: blur(2px);
        }
        .title {
            font-size: 1.625rem;
        }
        .description {
            font-size: 1rem;
            line-height: 1rem;
            color: #2E353D;
            margin-top: 24px;
            margin-bottom: 16px;

            @media (min-width: 361px) {
                margin-top: 48px;
                margin-bottom: 32px;
            }
        }
        .btn {
            font-size: 1rem;
        }
    }
    #complete {
        & {
            text-align: center;
        }
        .title {
            font-size: 1.375rem;
        }
    }
    .title {
        font-size: 1.375rem;
    }
    .subtitle {
        font-size: 1.125rem;
    }
    .btn {
        font-weight: 900;
        font-size: 0.875rem;
    }
    .btn-multiline {
        line-height: 1rem;
    }
    .icon {
        width: 64px;
        margin: auto auto 35px;

        img {
            width: 100%;
        }
    }
    .name-placeholder {
        color: #E0E0E0;
    }
    .name {
        color: color("grey", "darken-4");
    }
    .name-placeholder,
    .name {
        @include proxima-font-semibold();
        font-size: 38px;
        text-transform: capitalize;
    }
    .generate-btn {
        width: 136px;
        height: 136px;
        border-radius: 100%;
        display: flex;
        margin: auto;

        span {
            color: color("shades", "white");
            text-transform: capitalize;
            @include proxima-font-semibold();
            font-size: 16px;
            flex: 1;
            align-self: center;
        }
    }
    .pills {
        & {
            display: flex;
            flex-direction: row;
            border-radius: 4px;
            border: 2px solid #9E9E9E;
        }
        & > li,
        & > li > a {
            color: #9E9E9E;
        }
        & > li {
            flex: 1 1 auto;
            line-height: 44px;
            font-weight: 900;
            font-size: 1.25rem;

            &:nth-child(2) {
                border-left: 2px solid #9e9e9e;
                border-right: 2px solid #9e9e9e;
            }
        }
        & > li > a {
            display: block;
        }
        & > li.active,
        & > li.active > a {
            color: #FFFFFF;
        }
        & > li.active {
            background-color: #00E676;
        }
        & > li.selected-pill {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        & > li.selected-pill > .material-icons {
            padding-left: 8px;
        }
    }
    .fixed-bottom-btn-container {
        & {
            left: 0;
            bottom: 0;
            width: 100%;
            padding: 8px 24px 16px;
            position: fixed;
        }
        @media (min-width: 361px) {
            padding: 8px 32px 32px;
        }
        @media (min-width: 992px) {
            right: 0;
            width: 664px;
            margin: 0 auto;
        }
    }
    .carousel-item-content {
        padding: 0 24px;
    }
    .carousel-img {
        width: 100%;

        img {
            width: 100%;
        }
    }
    %material-icons {
        direction: ltr;
        display: inline-block;
        font-family: 'Material Icons';
        font-size: 24px;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
        line-height: 1;
        text-transform: none;
        white-space: nowrap;
        word-wrap: normal;
        -webkit-font-smoothing: antialiased;
    }
    .nav-control {
        position: absolute;
        bottom: 40px;
        width: 100%;
        height: 35px;
        -webkit-tap-highlight-color: transparent;

        &::before,
        &::after {
            @extend %material-icons;

            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            font-size: 24px;
            color: #9E9E9E;
            vertical-align: middle;
            pointer-events:auto;
            font-feature-settings: "liga" 1;
        }
        &::before {
            content: "arrow_back";
            left: 50px;
        }
        &::after {
            content: "arrow_forward";
            right: 50px;
        }
    }
    // .carousel {
    //     height: 375px !important;

    //     .carousel-item {
    //         min-height: unset;
    //     }
    //     .indicators {
    //         bottom: 0;
    //     }

    //     @media (min-width: 321px) {
    //         height: 390px !important;

    //         .carousel-item {
    //             height: 390px;
    //         }
    //     }
    // }
    .carousel .indicators .indicator-item {
        background-color: rgba(0,0,0,0.5);
        vertical-align: middle;
    }
    .carousel .indicators .indicator-item.active {
        height: 15px;
        width: 15px;
    }
    .modal.tip {
        overflow: hidden;

        & {
            height: 468px;
            width: 256px;
            background-color: #FFF;
        }
        .modal-content {
            padding: 16px 24px;
        }
        .subtitle {
            margin-bottom: 1.125rem;
        }
        @media (min-width: 321px) {
            .modal-content {
                padding: 24px;
            }
            .subtitle {
                margin-bottom: 1.68rem;
            }
        }
    }
    @media (min-width: 768px) {
        .modal.tip {
            top: 20vh !important;
        }
    }
    @media (max-width: 767px) {
        .modal.tip {
            top: 0 !important;
        }
    }
    @media (max-width: 991px) {
        .modal.tip {
            // height: calc(100% - 60px);
            margin: 30px auto;
            max-height: 100%;
        }
    }
}

.autocomplete-option {
    & {
        padding: 16px;
        position: relative;
    }
    &:not(:last-child) {
        border-bottom: 1px solid rgba(#000, 0.1);
    }
    label {
        min-height: 25px;
        height: auto;
        display: block;
        color: color("shades", "black");
    }
}
.autocomplete-option-label::first-letter {
    text-transform: uppercase;
}
.verify-dropdown {
    text-align: left;
}
