#events {
  .lightning-icon {
    color: #00E676;
    font-size: 14px;
    vertical-align: middle;
  }
}

#event-details {
    position: relative;
    height: 100vh;

    &.event-status--pending {
      @media #{$medium-and-up} {
        .video-date.event-details-wrapper,
        .event-details-wrapper {
          bottom: -70px;
        }
        .video-date .notes,
        .notes {
          bottom: -50px;
        }
      }
      @media #{$small-and-down} {
        .video-date.event-details-wrapper,
        .event-details-wrapper {
          margin-top: 135px;
          bottom: 0;
        }
        .video-date .notes,
        .notes {
          bottom: -115px;
        }
      }
      @media #{$extra-small-and-down} {
        .video-date.event-details-wrapper,
        .event-details-wrapper {
          margin-top: 165px;
        }
        .video-date .notes,
        .notes {
          bottom: -145px;
        }
      }
    }
    .where-is-she-icon {
      background-image: url('../../img/events/ic-where.svg');
      height: 50px;
      width: 50px;
      transform: scale(0.5);
      background-size: contain;
      background-position: top;
      background-repeat: no-repeat;
      text-align: center;
      margin: -14px auto -12px;
    }
    .unread-message {
        padding: 2px 8px;
        font-weight: bold;
        font-size: 10px;
        border-radius: 99%;
        color: color("shades", "white");
        position: absolute;
        top: 3px;
        right: 3px;
        background: #c51162;
    }
    .title {
        margin-top: 0;
        margin-bottom: 12px;
        font-size: 22px;

        @media #{$large-and-up} {
            margin-top: 30px;
        }
        .tooltip {
            display: inline-block;
        }
    }
    .cannot-slide {
        position: absolute;
        height: 100%;
        width: 90%;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 100;
    }
    .slider-container {
        padding: 0;
        margin: auto;
    }
    #controls {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        background: transparent;
        padding: 0;

        .btn {
            flex: 1;
            height: 44px;

            &:first-child {
                margin-right: 4px;
            }
            &:last-child {
                margin-left: 4px;
            }
        }
        &.decline-controls {
            flex-direction: column;
        }
        &.controls-disabled {
            color: #C4C4C4;

            .control-btn:active {
                background-color: initial;
                color: #C4C4C4;
            }
        }
        .control-btn {
            height: 80px;
            flex: 1 1 calc(100%/3 - 4px);
            border: 1px solid #EEE;
            margin: 2px;
            font-size: 13px;
            padding: 20px 0;
            background: #FFF;

            .ic-where {
                fill: #2F3740;
            }
            &.disabled {
                color: #C4C4C4;
                background: #E8EDF2;

                .where-is-she-icon {
                  background-image: url('../../img/events/ic-where-disabled.svg');
                }
                .ic-where {
                    fill: #C4C4C4 !important;
                }
                &:active {
                    background-color: initial;
                    color: #C4C4C4;

                    .ic-where {
                        fill: #C4C4C4 !important;
                    }
                }
            }
            &:active {
                background-color: #C51162;
                color: color("shades", "white");

                .ic-where {
                    fill: color("shades", "white");
                }
            }
            i.material-icons {
                display: block;
            }
        }
    }
    &.event-status {
        &.event-status--confirmed,
        &.event-status--ready-pre {
            background: linear-gradient(180deg,#00D96F 45%,#f7fafc 45%, #f7fafc 55%);

            nav {
                background: #00D96F;
            }
        }
        &.event-status--cancelled-agent,
        &.event-status--cancelled-provider,
        &.event-status--cancelled-client,
        &.event-status--expired,
        &.event-status--declined {
            background: linear-gradient(180deg,$krypton-grey-lighten-2 45%,#f7fafc 45%, #f7fafc 55%);

            nav {
                background: $krypton-grey-lighten-2;
            }
            .notes {
                ul {
                    span {
                        color: color("krypton-grey", "lighten-1");
                    }
                    li {
                        color: color("krypton-grey", "lighten-1");
                        &::before {
                            color: color("krypton-grey", "lighten-1");
                        }
                    }
                }
            }
        }
        &.event-status--preparing,
        &.event-status--pending {
            background: linear-gradient(180deg,#C51162 45%,#f7fafc 45%, #f7fafc 55%);

            nav {
                background: #C51162;
            }
        }
        &.event-status--reactivated {
            background: linear-gradient(180deg,color("krypton-orange", "base") 45%,#f7fafc 45%, #f7fafc 55%);

            nav {
                background: color("krypton-orange", "base");
            }
            #content {
                color: color("krypton-grey", "darken");
            }
            .notes {
                ul {
                    span {
                        color: color("krypton-grey", "lighten-1");
                    }
                    li {
                        color: color("krypton-grey", "lighten-1");
                        &::before {
                            color: color("krypton-grey", "lighten-1");
                        }
                    }
                }
            }
        }
        #content {
            color: color("shades", "white");
            padding-bottom: 0;
            padding-top: 0;
            min-height: 50vh;
            background: transparent;
        }
        nav {
            color: color("shades", "white");
        }
    }
    .has-custom-note {
      .notes {
        bottom: 0px;

        @media #{$medium-and-down} {
          bottom: -45px;
        }
        @media #{$extra-small-and-down} {
          bottom: -130px;
        }
      }
    }

    .notes {
        background: color("shades", "white");
        padding: 1px 16px;
        color: #000;
        margin: auto;
        width: 100%;
        border-radius: 4px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        max-width: calc(100vw - 64px);

        @media #{$medium-and-down} {
          bottom: -35px;
        }
        @media #{$small-and-down} {
          min-width: 270px;
        }
        @media #{$extra-small-and-down} {
          max-width: 290px;
          min-width: 0;
          margin: auto;
          bottom: -75px;
        }
        ul {
            strong {
                display: block;
                font-size: 14px;
            }
            span {
                color: #757575;
                font-size: 13px;
            }
            li {
                line-height: 1rem;
                padding-left: 40px;
                margin-bottom: 12px;
                position: relative;

                &.lilac::after {
                    left: 20px;
                }
                &.checked:before {
                    content: '';
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    left: 15px;
                    color: color("krypton-green", "base");
                    content: 'check_circle';
                    font-family: "Material Icons";
                    font-feature-settings: "liga" 1;
                    background-size: contain;
                }
                &.pending:before {
                  content: '';
                  border-radius: 50%;
                  width: 8px;
                  height: 8px;
                  background-color: color("krypton-pink", "base");
                  position: absolute;
                  left: 18px;
                  top: 5px;
                }
                &.pending-check:before {
                  content: 'check_circle_outline';
                  font-family: "Material Icons";
                  font-feature-settings: "liga" 1;
                  width: 8px;
                  height: 8px;
                  position: absolute;
                  left: 15px;
                  top: 0;
                }
            }
        }
    }
    .schedule {
        font-size: 13px;
        display: flex;
        justify-content: center;

        @media #{$medium-and-down} {
          max-width: 312px;
          min-width: 270px;
          margin: auto;
        }
        @media #{$small-and-down} {
          max-width: 312px;
          min-width: 270px;
          margin: auto;
        }
        @media #{$extra-small-and-down} {
          max-width: 290px;
          min-width: 0;
          margin: auto;
        }
        .col.s6 {
            padding: 0;
        }
    }
    .request-acceptance {
        border-top: 1px solid color("grey", "lighten-3");
        border-left: 1px solid color("grey", "lighten-3");
        border-right: 1px solid color("grey", "lighten-3");
        margin: 0 40px;
        position: relative;
        padding: 20px 0 10px;
        p {
            margin: 0;
        }
        .coin {
            width: 31px;
            height: 31px;
            position: absolute;
            border: 1px solid #D8AA2B;
            border-radius: 50%;
            background-color: #F9E425;
            left: 0;
            right: 0;
            margin: auto;
            top: -17px;
            color: #D8AA2B;
            .material-icons {
                font-size: 18px;
                vertical-align: middle;
            }
        }
    }
    .accept-btn,
    .decline-btn {
        font-size: 14px;
    }
    .accept-btn {
        width: calc(100% - 30px);
    }
    small {
        font-size: 13px;
        color: #757575;
    } // Media Queries
    @media screen and (min-width: 375px) {
        // &.event-status--pending {
        //     #controls {
        //         // padding-top: 7rem;
        //     }
        // }
        #controls {
            .control-btn {
                margin: 4px;
                flex: 1 1 calc(100%/3 - 8px);
            }
        }
        .schedule {
            font-size: 16px;
        }
    }
    .video-date {
      &.has-custom-note {
        .notes {
          bottom: 0;

          @media #{$extra-small-and-down} {
            bottom: 5px;
          }
          @media #{$medium-and-down} {
            bottom: -10px;
          }
          @media #{$large-and-up} {
            bottom: 50px;
          }
        }
      }
      .notes {
        bottom: 10px;

        @media #{$large-and-up} {
          bottom: -35px;
        }
        @media #{$medium-and-up} {
          bottom: 50px;
        }
        @media #{$medium-only} {
          bottom: 40px;
        }
      }
    }
    .event-details-wrapper {
      height: calc(30vh - 90px);
      margin-top: 90px;
      position: relative;

      @media #{$medium-and-up} {
        height: calc(30vh - 46px);
        margin-top: 0;
        bottom: -55px;
      }
      @media #{$medium-only} {
        height: calc(30vh - 90px);
        margin-top: 15px;
      }
      @media #{$small-and-down} {
        height: calc(30vh - 90px);
        margin-top: 90px;
      }
      @media #{$extra-small-and-down} {
        margin-top: 120px;
      }
      &.has-custom-note {
        @media #{$extra-small-and-down} {
          margin-top: 175px;
        }
      }
      &.video-date {
        margin-top: 0;
        height: calc(30vh - 46px);
        bottom: 0;

        &.has-custom-note {
          @media #{$medium-and-down} {
            bottom: -55px;
          }
        }
        @media #{$small-and-down} {
          bottom: -45px;
        }
      }
      .event-details-control {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        padding-bottom: 37px;
        margin: -25px auto 0;
        max-width: calc(100vw - 64px);
        top: 0;

        @media #{$small-and-down} {
          min-width: 270px;
        }
        @media #{$extra-small-and-down} {
          max-width: 290px;
          min-width: 0;
        }
      }
    }
}

#pending-event-modal {
    .modal-footer {
        border-top: 1px solid #EEE;
        text-align: right;
    }
    .apv-card {
        box-shadow: none !important;
        text-align: center !important;
        background-color: transparent !important;

        .card-header__flex.card-header__flex--mid {
            @media #{$small-and-down} {
                max-width: 154px !important;
            }
        }

        .event-details-hotel-name {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #2E353D;
            margin-left: -12px;

            .material-icons {
              font-size: 20px;
              margin-right: 4px;
            }
        }
    }
    .lilac.lilac--red::after {
        top: 1px !important;
    }
}

#pending-event-modal,
#event-card {
    &.event-card--rejected,
    &.event-card--cancelled {
        .schedule__countdown,
        .schedule,
        .schedule small,
        .amount,
        .date {
        color: #c4c4c4 !important;
        font-weight: 400 !important;
        }
    }
    &.event-card--rejected .card-header:after {
        content: " ";
        background: rgba(117, 117, 117, 0.2);
        position: absolute;
        border-radius: 4px;
        height: 100%;
        width: 100%;
        left: 0;
    }
    &.soon {
        .apv-card {
            .date,
            .schedule__countdown {
                color: #c51162;
            }

            .event-details-hotel-name {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #2E353D;
                margin-left: -12px;

                .material-icons {
                  font-size: 20px;
                  margin-right: 4px;
                }
            }
        }
    }
    .switch__lever--no-margin {
        margin: 0;
    }
    .switch--green {
        input[type="checkbox"]:checked + .lever {
        background-color: rgba(0, 230, 118, 0.5);
        }
        input[type="checkbox"]:checked + .lever:after {
        background-color: #00e676;
        }
    }
    .valign-bottom {
        vertical-align: bottom;
    }
    .card-controls {
        margin-bottom: 8px;
        margin-top: 16px;
    }
    .apv-card {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        border-radius: 4px;
        margin-bottom: 8px;

        .card-date {
            font-size: 14px;
            color: #757575;
        }
        .schedule {
            small {
                font-size: 14px;
                color: #757575;
            }
        }
        .card-header {
        min-height: 64px;
        justify-content: space-between;
        display: flex;
        align-items: center;
        position: relative;

            .card-header__flex {
                flex: 1;

                &.card-header__flex--mid {
                    flex: 3;

                    @media #{$small-and-down} {
                        max-width: 208px;
                    }
                    @media #{$extra-small-and-down} {
                        max-width: 165px;
                    }
                }
            }
        }
        .schedule__countdown {
            color: color("grey", "darken-4");
        }
        .schedule,
        .location {
            margin: 0;
        }
        .location {
            font-size: 14px;
            width: 100%;

            .event-details-hotel-name {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #2E353D;
                margin-left: -12px;

                .material-icons {
                  font-size: 20px;
                  margin-right: 4px;
                }
            }

            @media #{$small-and-down} {
                p {
                    width: 90%;
                    text-overflow: ellipsis;
                    margin: auto;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }
        .amount {
        font-size: 14px;
        }
        .date {
        font-size: 12px;
        font-weight: bolder;
        font-family: sans-serif;
        }
    }
    .clearfix::after {
        content: "";
        display: block;
        clear: both;
    }
    .float-left {
        float: left;
    }
    .float-right {
        float: right;
    }
    .controls {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0 15px 15px;
    }
    .expiration-time {
        margin: 0 0 15px;

        span {
            font-size: 14px;
            background-color: #000;
            border-radius: 4px;
            padding: 5px;
            color: #fff;
        }
    }
    .respond-btn {
        margin-bottom: 10px;

        .btn {
            font-size: 1rem;
            padding: 0 1rem;
            width: 100%;

            @media screen and (max-width: 320px) {
                font-size: 11px;
                padding: 0 11px;
            }
            @media screen and (max-width: 414px) {
                font-size: 11px;
                padding: 0 11px;
            }
        }
    }
}

#event-checkin {
    height: 100vh;

    #content {
      background: linear-gradient(180deg, #00D96F 50%, #F7FAFC 50%);
    }
    .timer {
        width: 285px;
    }
    .icon-payment {
        background: url('/img/events/ic-payment.svg') no-repeat top left;
        height: 19px;
        width: 19px;
        display: inline-block;
        vertical-align: text-top;

        &.white-icon {
            background: url('/img/events/ic-payment-white.svg') no-repeat top left;
        }
    }
    .slide-to-unlock {
        width: 80%;
        margin: auto;

        @media #{$small-and-down} {
            width: 100%;
        }
    }
    .slide-wrap {
        width: 650px;
        position:relative;
    }
    .navbar {
        .pre {
            background: #00D96F;
        }

        nav {
            background: color("krypton-green", "base");
        }
    }
    .countdown-icon {
        width: 38px;
        margin: auto;
    }
    .countdown-timer {
        margin: 0;

        @media #{$small-and-down} {
            font-size: 2.56rem;
        }
    }
    .details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        justify-content: center;
    }
    .details {
        .material-icons {
            vertical-align: top;
        }
        > div {
            margin: 0 10px;
        }
    }
    .time-extension {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 80%;
        margin-top: 30px;

        @media (min-width: 992px) {
            width: 600px;
        }
        @media #{$small-and-down} {
            width: 100%;
        }
        @media #{$extra-small-and-down} {
            margin-top: 11px;
        }
        .time {
            background: transparent;
            flex: 1;
            border: 1px solid color("shades", "white");
            padding: 10px;
            margin: 0 7px;
            border-radius: 4px;
            font-weight: 600;
        }
        .time-extension-details {
            color: #959DA6;
            margin-top: 36px;

            @media #{$extra-small-and-down} {
                font-size: 15px;
            }
            .hours,
            .rate {
                margin: 0 7px;

                @media #{$extra-small-and-down} {
                    margin: 0 6px;
                }
            }
            .material-icons {
                vertical-align: top;
            }
        }
        .time-extension-details,
        .time-extension-controls,
        .controls {
            display: flex;
            justify-content: center;
        }
        .controls {
            .btn {
                margin: 0 4px;
                padding: 0 16px;

                @media #{$small-and-down} {
                    font-size: 14px;
                }
            }
        }
        .time-extension-controls {
            .added-time {
                border: 1px solid color("krypton-grey", "lighten-1");
                padding: 6px 8px;
                font-size: 20px;
                width: 44px;
                height: 44px;
            }
            .btn {
                margin: 0 4px;
                font-size: 2rem;
                height: 44px;
                width: 44px;
                padding: inherit;
            }
        }
    }
    .get-support-link {
        position: absolute;
        bottom: 32px;
        left: 0;
        right: 0;
    }
    #content .container {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        &:first-child {
            color: color("shades", "white");
            height: calc(50vh - 56px);

            @media #{$medium-and-up} {
                height: calc(50vh - 64px);
            }
        }
        &:last-child {
            height: 50vh;
        }
    }
}

#event-rate-feedback,
#event-rate {
    .rate-btns {
        display: flex;

        .rate {
            width: 56px;
            height: 56px;
            border-radius: 90%;
            display: flex;
            justify-content: center;
            border: 1px solid #EEE;
            align-items: center;
            margin: 0 16px;
            color: color("grey", "base");

            &.active {
                background: color("krypton-pink", "base");
                color: color("shades", "white");
            }
        }
    }
}

#event-rate-feedback {
    flex-direction: column;

    textarea.materialize-multiline {
        padding: 29px 0 10px 15px;
    }
    .content.positive-feedback {
        width: 100%;
    }
    .content.positive-feedback,
    .content.negative-feedback {
        padding-top: 0;
    }
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 12px;
        max-width: 600px;

        .new-note {

            .material-icons {
                vertical-align: text-bottom;
            }
        }

        textarea {
            box-sizing: border-box;
            height: 70px;
            width: 100%;
        }
        ul {
            padding-left: 40px;

            li {
                list-style-type: disc;

                .material-icons {
                    color: color("gold", "base");
                    font-size: 1rem;
                }
            }
        }
        .checkbox-container {
            width: 100%;
        }
        .p-note,
        .note {
            padding: 0;
            font-size: 14px;
            color: #2F3740;

            .material-icons {
                color: #BFB38F;
            }
        }
        .btn {
            height: 44px;
        }
    }
}

#event-rate {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .header {
        font-size: 20px;
        line-height: 2rem;
    }
    .please-rate {
        font-size: 2.38rem;
        color: color("krypton-green", "base");
        font-family: "Proxima Nova Semibold";
    }
    .content {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
    .note {
        width: 85%;
        border: 1px solid #EEE;
        padding: 30px;
    }
}

#event-declined {
  .availability-img {
    @media #{$medium-and-up} {
      max-width: 312px;
    }
  }
  .input-radio label {
    color: color("grey", "darken-4");
  }
  small {
    color: #78838F;
  }
  .title {
    font-size: 22px;
    text-transform: unset;
    margin: 0;
  }
}

#event-late {
    .content.container {
        padding: 0 16px;

        @media #{$small-and-down} {
            padding: 0;
            width: 90%;
        }
    }
    .header {
        font-size: 26px;
        color: #2F3740;
    }
    .border {
        padding: 20px 20px 34px;
        border: 1px solid #E1E6EB;
        border-radius: 4px;
        background: color("shades", "white");
    }
    .warning {
        font-size: 14px;
        background: #E1E6EB;
        border-radius: 4px;
        color: #757575;
        margin-bottom: 40px;

        .material-icons {
            font-size: 12px;
            vertical-align: top;
            margin-top: 4px;
        }
        div {
            display: inline-block;
            width: calc(100% - 17px);
        }
    }
    .success-notification {
        .success {
            color: color("krypton-green", "base");
            font-size: 38px;
        }
        .sub-title {
            font-size: 20px;
            color: #757575;
        }
        .be-on-time {
            font-size: 22px;
            color: color("grey", "darken-4");
        }
        .material-icons {
            font-size: 6rem;
            color: color("gold", "base");
        }
        .notes {
            font-size: 16px;
            color: #757575;

            strong {
                font-size: 22px;
                color: color("grey", "darken-4");
            }
        }
    }
    .navigator {
        display: flex;

        .btn {
            flex: 1;
            margin: 0 10px;

            @media #{$small-and-down} {
                margin: 0 5px;
            }
        }
    }
}

#event-whereis {
    .content.container {
        padding: 16px;
    }
    .chat-container {
        height: calc(100vh - 160px);
        position: relative;
        overflow: auto;

        .message-left {
          clear: both;
          width: 32%;
          margin: auto;
          text-align: center;
          border-radius: 4px;
          color: color("shades", "white");
          padding: 4px;
          background: #959DA6;
          margin-bottom: 10px;

          @media #{$small-and-down} {
            width: 50%;
          }
          @media #{$extra-small-and-down} {
            width: 65%;
          }
        }
    }
    .chat-controls {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 10px 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        textarea {
          padding: 16px !important;
          background: rgb(247, 250, 252);
          border-bottom: 2px solid #C51162;
          margin-bottom: 0;
          max-height: 40px;
          overflow: auto;
        }
        .input-field {
            flex: 1;
            margin-right: 14px;

            input {
                margin-bottom: 0;
            }
        }
    }
    .warning {
        color: #757575;
        background: #E1E6EB;
        border-radius: 4px;
        padding: 16px;
        margin-bottom: 16px;

        .material-icons {
            font-size: 12px;
            vertical-align: top;
            margin-top: 7px;
        }
        div {
            display: inline-block;
            width: calc(100% - 17px);
        }
    }
}

#chat-bubble {
    color: $krypton-grey-darken;
    position: relative;
    padding: 0 16px 16px 0;
    min-height: 100px;
    clear: both;

    .file-info {
        padding-bottom: 0;
        padding-top: 0;
    }
    .status {
        display: block;
        width: 90%;
        margin-top: 8px;
        margin-bottom: 16px;
    }
    .text {
        width: 90%;
        background: #aef3df;
        padding: 16px;
        border-radius: 4px;
        word-break: break-word;
        text-align: left;
    }
    &.reverse {
        .text {
            background: color("shades", "white");
        }

        &.me {
            .text {
                background: #aef3df;
            }
        }
    }
    &.me {
        padding-right: 0;
        padding-left: 16px;

        .text {
            background: color("shades", "white");
            float: right;
        }
        .status {
            float: right;
        }
    }
    .time-ago {
        color: $krypton-grey-lighten-2;
        font-size: 11px;
    }
}

#event-confirmed {
    .countdown {
        padding: 4px 8px;
        border-radius: 4px;
        background: #000;
        color: color("shades", "white");
        font-size: 13px;
    }
}

.navbar-event {
    &.navbar-reactivated {
        .amount-payable,
        .brand-logo {
            color: color("krypton-grey", "darken");
        }
        .amount-payable,
        .countdown,
        .brand-logo {
            @media #{$extra-small-and-down} {
                font-size: 11px;
            }
        }
    }

    .amount-payable,
    .countdown,
    .brand-logo {
        color: color("shades", "white");
    }
}

#event-cancelled {
    background: #F7FAFC;
    height: 100vh;

    .btn {
        font-size: 16px;
    }
    .navigator {
        display: flex;

        .btn {
            flex: 1;
            margin: 0 10px;

            @media #{$small-and-down} {
                margin: 0 5px;
            }
        }
    }
    .footer-note {
        color: #959DA6;
    }
    .subheading {
        font-size: 20px;
        margin-top: 24px;
        margin-bottom: 24px;
    }
}

.promo-card-events-container {
    .promo-cta {
        padding-top: 96px !important;
    }
    .right-panel {
        align-items: flex-end!important;
    }
}

#navbar-event-back-btn {
  display: flex;
  align-items: center;

  @media #{$small-and-down} {
    font-size: 12px;
  }
}
