.forgotpass-page,
.register-page,
.login-page {
  min-height: 100vh;

  .btn {
      font-size: 16px;
  }
  #content {
      padding-top: 53px;
  }
  #header {
      @include proxima-font-semibold();

      line-height: normal;
      padding: 40px 0;
      font-size: 32px;
  }
}

.forgotpass-page #header {
    padding: 48px 0;
}

#forgotpass-desktop,
#register-desktop,
#login-desktop {
    display: none;

    @media screen and (min-width: 1025px) {
        display: block;

        .container {
            width: 832px;
        }
    }
    .card {
        width: 784px;
        padding: 20px 156px 65px;
        border-radius: 8px;
        box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
    }
}

#forgotpass-mobile,
#register-mobile,
#login-mobile {
    display: none;
    padding-top: 53px;

    @media screen and (min-width: 280px) and (max-width: 1024px) {
        display: block;
    }
}

#forgotpass-desktop,
#forgotpass-mobile {
   .email-input > input {
       margin-bottom: 24px;
       background: #F5F7FA;
   }
}
