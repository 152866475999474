@import "../materialize/custom/v2/buttons";

#register-new, #legal-new {
  display: block;
  position: relative;
  width: 100%;
  height: 100dvh;

  .wrapper {
    width: 100%;
    height: 100%;
    display: block;
    background-color: #ffffff;

    @media screen and (min-width: 992px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }

    .content__area {
      height: 100%;
      width: 100%;

      @media screen and (min-width: 992px) {
        width: 50%;
      }

      &:first-of-type {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(0.38turn, #7638FA, #D300C5, #FF0073);
        text-align: center;
        z-index: 5;
        height: 80vh;

        &.--success {
          background: linear-gradient(0.38turn, #06D6A0, #FFD600);
        }

        @media screen and (min-width: 992px) {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          padding: 48px 32px;
          height: 100dvh;
        }

        img#k-logo {
          display: none;
          width: 32px;
          position: absolute;
          top: 32px;
          left: 48px;

          @media screen and (min-width: 992px) {
            display: block;
          }
        }
      }

      &:nth-of-type(2) {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        background: white;
        padding: 0 24px 105px;
        min-height: 320px;
        overflow-y: auto;
        overflow-x: hidden;

        &.drawer {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          height: auto;
          max-height: calc(100dvh - 75px);

          @media screen and (min-width: 992px) and (orientation: landscape) {
            max-height: unset;
          }

          @media screen and (min-height: 1024px) and (orientation: portrait) {
            height: 80vh;
          }

          &:not(.--with-long-content):not(.--with-photo-content):not(.max-height-mobile) {
            @media screen and (max-width: 991px) {
              position: fixed;
            }
          }

          .content__title--steppers {
            font-size: 32px;
            color: #2E353D;
          }

          .drawer-icon img {
            max-width: 90px;
            object-fit: contain;

            @media screen and (min-width: 992px) {
              max-width: unset;
            }
          }
        }

        &.no-actions {
          padding-bottom: 0;
        }

        .selected-text, .photo-text {
          font-family: 'Proxima Nova Semibold';
          font-size: 16px;
        }

        .selected-text {
          color: #2F3740;

          @media screen and (min-width: 992px) {
            font-size: 20px !important;
          }
        }

        .photo-text {
          color: #2F3740;
          padding: 24px 0;
          text-align: center;

          @media screen and (max-width: 991px) {
            background-color: white;
            width: 100%;
            position: fixed;
            left: 0;
            bottom: 75px;
            padding: 16px 0;
            text-align: center;
            z-index: 1;
          }
        }

        .hint {
          font-size: 14px;
          color: #2F3740;
          margin-top: 32px;

          @media screen and (min-width: 992px) {
            font-size: 16px;
            margin-top: 44px;
          }
        }
      }

      &.disable-vertical-scroll {
        overflow-y: hidden;
      }

      p {
        color: #2F3740;
        font-size: 16px;
        word-break: break-word;
      }

      a {
        color: #DC0064;
      }
    }

    .content__stepper {
      font-size: 18px !important;
      font-family: 'Proxima Nova Semibold';
      margin: 0;
      position: absolute;

      &--mobile {
        color: white !important;
        top: 14px;
        left: 24px;
        display: block;

        @media screen and (min-width: 992px) {
          display: none;
        }
      }

      &--desktop {
        color: #2F3740;
        top: 36px;
        left: 48px;
        display: none;

        @media screen and (min-width: 992px) {
          display: block;
        }
      }

      p {
        margin: 0;
        font-size: 18px !important
      }
    }

    div.content__stepper {
      width: 100%;

      .helper__btn {
        min-width: 61px;
        padding: 0 16px;
        height: 33px;
        border-radius: 17px;
        background: hsla(212, 15%, 22%, .2);
        cursor: pointer;
        display: flex;
        align-items: center;

        span {
          color: #2F3740;
          font-size: 14px;
        }
      }

      &--mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        left: 0;
        padding: 0 24px;

        @media screen and (min-width: 992px) {
          display: none;
        }

        p {
          color: white !important;
        }

        .helper__btn {
          background: hsla(0, 0%, 100%, .3);

          span {
            color: white;
          }
        }
      }

      &--desktop {
        left: 0;
        display: none;
        padding: 0 48px;

        @media screen and (min-width: 992px) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    .content__title {
      &--desktop {
        color: white;
        font-size: 48px;
        display: none;

        @media screen and (min-width: 992px) {
          display: block;
        }
      }

      &--mobile {
        color: #2F3740;
        font-size: 26px;
        text-align: center;
        margin-top: 32px;
        margin-bottom: 32px;

        @media screen and (min-width: 992px) {
          display: none;
        }
      }
    }

    .drawer-icon {
      margin-bottom: 13px;

      &.--no-selected-text {
        margin-bottom: 45px;
      }

      @media screen and (min-width: 992px) {
        margin-bottom: 17px;

        &.--no-selected-text {
          margin-bottom: 99px;
        }
      }
    }

  }

  .actions {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 75px;
    border-top: 1px solid #CED6E0;
    padding: 0 24px;
    background-color: white;
    z-index: 99;

    @media screen and (min-width: 992px) {
      width: 50%;
      height: 95px;
      padding: 0 48px;
    }
  }

  .top-bar {
    position: fixed;
    top: 0;
    right: 0;
    background-color: white;
    height: 55px;
    border-bottom: 1px solid #CED6E0;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 24px;
    z-index: 99;

    @media screen and (min-width: 992px) {
      width: 50%;
      padding: 0 40px;
      height: 71px;
    }

    &--drawer {
      @extend .top-bar;
      position: sticky;

      @media screen and (min-width: 992px) {
        position: fixed;
      }

      i {
        color: #707070;
        padding-top: 2px;
        margin-left: -5px;
      }

      a {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: #2f3740bf !important;
      }

      .label {
        font-family: 'Proxima Nova Semibold';
        color: #2F3740;
        font-size: 20px;
        padding-top: 5px;
      }

      .cancel-upload-label {
        font-family: 'Proxima Nova Semibold';
        color: #2F3740;
        font-size: 16px;
        text-transform: uppercase;
        padding-top: 5px;
      }
    }
  }
}

#register-new, #legal-new {
  > div {
    height: 100%;
  }

  .overlay {
    filter: brightness(0.3);
  }
}

#register-new {
  .wrapper .content__area:nth-of-type(2) {
    @media screen and (min-height: 1024px) and (orientation: portrait) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media screen and (min-width: 992px) {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      bottom: unset;
      left: unset;
      padding: 0 0 95px;
    }

    &.max-height-mobile {
      @media screen and (max-width: 991px) {
        align-items: unset;
        height: inherit;
        max-height: calc(100dvh - 75px);

        &:not(.no-actions) {
          padding-bottom: 150px;
        }
      }
    }

    &.--with-photo-content {
      align-items: unset;
      height: inherit;
      max-height: calc(100dvh - 47px);

      @media screen and (min-width: 992px) and (orientation: landscape) {
        align-items: center;
        max-height: unset;
      }
    }

    &.--with-long-content {
      align-items: unset;
      height: inherit;
      max-height: calc(100dvh - 75px);
    }

    &.--with-both-bars {
      padding-top: 0;
      padding-bottom: 75px;

      @media screen and (min-width: 992px) and (orientation: landscape) {
        padding-top: 110px;
        padding-bottom: 95px;
      }
    }

    &.--with-both-bars-crop {
      padding-top: 0;
      padding-bottom: 131px;

      @media screen and (min-width: 992px) and (orientation: landscape) {
        padding-top: 110px;
        padding-bottom: 95px;
      }
    }

    &.no-y-padding {
      padding-left: 0;
      padding-right: 0;
    }
  }

  #agreement {
    .content__area:nth-of-type(2) .content {
      max-width: 464px;
      margin: 0 auto;

      img {
        margin-top: 10px;
        margin-bottom: 25px;

        @media screen and (min-width: 992px) {
          margin-top: unset;
        }
      }
    }
  }

  > div:first-of-type {
    .content__area:nth-of-type(2) .content {
      max-width: 464px;
      margin: 0 auto;

      @media screen and (min-width: 992px) {
        padding: unset;
      }

      .step {

        &__label, &__title, &__subtitle {
          font-family: 'Proxima Nova Semibold';
          color: #2E353D;
        }

        &__label {
          font-size: 14px;

          @media screen and (min-width: 992px) {
            font-size: 16px;
          }
        }

        &__title {
          font-size: 22px;

          @media screen and (min-width: 992px) {
            font-size: 24px;padding: unset;
          }
        }

        &__subtitle {
          font-size: 18px;

          @media screen and (min-width: 992px) {
            font-size: 20px;
          }
        }

        &:not(.active) {
          &:not(:last-child) {
            border-bottom: 1px solid #E0E0E0;
          }

          &:not(.done) > div {
            color: #2F3740 !important;
            opacity: 0.7;
          }

          .btn {
            display: none;
          }
        }

      }
    }
  }

  #overview {
    .edit-link {
      font-family: 'Proxima Nova Semibold';
      font-size: 20px;
    }

    .step-check {
      vertical-align: bottom;
      margin-bottom: 16px;

      @media screen and (min-width: 992px) {
        margin-bottom: 8px;
      }
    }
  }

  #photo-tips {
    .container {
      margin-top: unset;
    }

    .carousel-item-content {
      strong.subtitle {
        font-size: 22px;
        font-family: 'Proxima Nova Semibold';
        color: #2F3740;
      }

      p {
        font-size: 18px;
        color: #2F3740;
      }
    }
  }

  #rates {
    .checkbox-label {
      font-family: 'Proxima Nova Semibold';
      font-size: 16px;

      @media screen and (min-width: 992px) {
        font-size: 18px;
      }
    }
  }

  .color-swatch {
    & {
      @media (max-width: 400px) {
        padding: 0 !important;
        margin: auto !important;
        justify-content: center !important;
      }
    }

    > div {
      cursor: pointer;
      width: 68px !important;
      height: 68px !important;
      height: 68px !important;

      @media (min-width: 992px) {
        width: 103px !important;
        height: 103px !important;
        line-height: 103px !important;
      }
    }

    > div > img {
      height: 18px;
      width: 24px;

      @media (min-width: 992px) {
        height: 28px;
        width: 36px;
      }
    }

    .brown {
      background-color: #9E6B4A !important;
    }
    .blue {
      background-color: #A1CAF1 !important;
    }
    .green {
      background-color: #19A337 !important;
    }
    .grey {
      background-color: #767694 !important;
    }
    .blonde {
      background-color: #FBE7A1 !important;
    }
    .black {
      background-color: #1E2023 !important;
    }
    .red {
      background-color: #A52A2A !important;
    }
    .dark-brown {
      background-color: #5A3825 !important;
    }
  }

  #city .tooltip span {
    color: #2F3740;
    font-size: 14px;
  }

  #city {
    .content__title {
      margin-top: 32px;
      margin-bottom: 32px;
    }
    .drawer-icon {
      margin-bottom: 13px;
    }
    .tooltip-container {
      margin-bottom: 32px;
    }
  }

  .selected-text-container {
    & {
      margin-bottom: 45px;

      @media (min-width: 992px) {
        margin-bottom: 116px;
      }
    }
    & > p {
      margin: 0;
    }
  }

  #bra-cup {
    .natural-label {
      font-size: 16px;
      color: #2E353D;
      font-family: 'Proxima Nova';

      @media (min-width: 992px) {
        font-size: 18px;
      }
    }
  }

  #verify {
    .content__title--mobile {
      font-size: 32px;
    }

    .padding-bottom {
      @media screen and  (max-width: 991px) {
        padding-bottom: 200px;
      }
    }

    .drawer-icon {
      margin-bottom: 31px;

      @media screen and  (min-width: 992px) {
        margin-bottom: 99px;
      }
    }

    .context {
      text-align: center;
      margin-bottom: 40px;
    }

    .title {
      font-family: 'Proxima Nova Semibold';
      font-size: 26px;
      color: #2E353D;
      margin-bottom: 24px;
      display: inline-block;

      @media screen and  (min-width: 992px) {
        font-size: 28px;
      }
    }

    .subtitle {
      font-family: 'Proxima Nova';
      font-size: 16px;
      color: #2E353D;

      @media screen and (min-width: 992px) {
        font-size: 18px;
      }
    }
  }

  // parent of success screens {
  #registration-complete {
    .content__title--mobile {
      font-size: 38px;
    }

    .text {
      font-size: 18px;
      margin: 0;
      margin-top: 24px;
    }
  }

  #complete {
    .drawer-icon {
      margin-bottom: 41px;

      @media screen and  (min-width: 992px) {
        margin-bottom: 71px;
      }
    }

    .subtitle {
      font-size: 22px;

      @media screen and  (min-width: 992px) {
        font-size: 24px;
      }
    }
  }

  #registration-complete-desktop {
    .content {
      text-align: center;
    }

    .qrcode {
      margin-top: 36px;
      margin-bottom: 24px;

      svg {
        height: 120px !important;
        width: 120px !important;
      }
    }

    .qr-code-text {
      font-size: 14px;
      color: #78838F;
      margin: 0 auto;
      max-width: 226px;
      padding-bottom: 80px;
      display: inline-block;
    }

    .drawer-icon {
        margin-bottom: 30px;
    }
  }

  #registration-complete-mobile {
    .drawer-icon {
      margin-bottom: 32px;
    }
  }
}

#legal-new {
  .wrapper .content__area:nth-of-type(2) {

    @media screen and (min-width: 992px) {
      position: relative;
      bottom: unset;
      left: unset;
    }
  }

  .content__area:nth-of-type(2) .content {
    max-width: 328px;
    padding-top: 80px;
    margin: 0 auto;

    @media screen and (min-width: 992px) {
      padding-top: 103px;
    }

    img {
      margin-bottom: 69px;
    }

    h1 {
      color: #2F3740;
      font-size: 26px;
    }

    h2 {
      color: #2F3740;
      font-size: 22px;
    }

    p {
      color: #2F3740;
      font-size: 18px;
    }
  }
}

#personality {
  #name {
    .name, .name-placeholder {
      font-family: 'Proxima Nova Semibold';
      text-align: center;
      font-size: 38px;
      color: #2F3740;
    }

    .btn.generate-btn {
      color: white;
      width: 136px;
      height: 136px;
      font-size: 26px;
      margin: 40px auto 31px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(0.38turn, #FF0073, #DC0064);

      @media screen and (min-width: 992px) {
        width: 180px;
        height: 180px;
        font-size: 34px;
        margin: 71px auto 41px;
      }
    }
  }
}

.tiers {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 54px;
  margin-bottom: 84px;
  position: relative;

  @media screen and (min-width: 992px) {
    margin-top: unset;
    margin-bottom: 146px;
  }

  &::after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 56px;
    height: 90px;
    transform: translate(75%, 50%);
    background-image: url('/img/registration/checkout/rate-girl.svg');
  }

  li.select-tier-pill {
    background-color: #ECFBF7;
    border-radius: 16px;
    width: 99px;
    height: 80px;
    font-family: 'Proxima Nova Semibold';
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    will-change: filter;

    strong {
      font-size: 24px;
      color: #2E353D;

      .smaller {
        color: #2E353D;
      }
    }

    strong .smaller {
      font-size: 20px;
    }

    span {
      font-size: 16px;
      color: #78838F;
    }

    &.active.selected-pill {
      width: 146px;
      height: 118px;
      border: 2px solid #06D6A0;
      border-radius: 24px;
      filter: drop-shadow(0 3px 6px #00000029);

      &::before {
        content: " ";
        position: absolute;
        top: -23px;
        left: 50%;
        height: 18px;
        width: 19px;
        transform: translate(-50%, 0);
        background-image: url('/img/registration/checkout/arrow.svg');
      }

      strong {
        font-size: 36px;
      }

      strong .smaller {
        font-size: 30px;
      }
    }
  }
}

/*
* Pages' graphics used are for the desktop ones
* to cater varying images' width for each of the page on mobile view
* we can set here the width/max width of the images
*/
#agreement .content__image img {
  max-width: 55px;

  @media screen and (min-width: 992px) {
      max-width: unset;
  }
}

.iphone #register-new {
  .wrapper .content__area:nth-of-type(2) {
    &.max-height-mobile {
      @media screen and (max-width: 991px) {
        &:not(.no-actions) {
          padding-bottom: 240px;
        }
      }
    }

    &:not(.max-height-mobile) {
      @media screen and (max-width: 991px) {
        .content {
          padding-bottom: 105px;
        }
      }
    }
  }
}
