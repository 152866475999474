#toast-container {
  @media only screen and (min-width: 993px) {
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    max-width: 86%;
    width: 600px;
    height: 100px;
  }
  @media only screen and (max-width: 992px) and (min-width: 601px) {
    left: 5%;
    width: 90%;
    top: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    height: 100px;
  }
}

#toast-container.tooltip-container {
  padding: 0 8px;

  @media only screen and (max-width: 600px) {
    bottom: unset;
    top: 0%;
  }

  .toast.custom-tooltip {
    background: #2E353D;
    border-radius: 8px;
    color: white;
    font-size: 13px;

    i {
      cursor: pointer;
    }
  }
}

#toast-container.tooltip-container-new {
  padding: 0 8px;
  height: unset;

  &.bottom {
    top: unset;
    bottom: 32px;
  }

  &.top {
    top: 32px;
    bottom: unset;
  }

  .toast.custom-tooltip {
    background: #2F3740;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    min-height: 52px;
    justify-content: flex-start;
    padding: 17px 20px;

    img {
      margin-right: 14.5px;
    }
  }

  &.with-cta {
    &.bottom {
      top: unset;
      bottom: 80px;
    }
  }

  &.overlay {
    opacity: 0.9;
    align-items: flex-start;

    .toast.custom-tooltip {
      align-items: flex-start;
      opacity: 0.9 !important;
    }
  }

  &.unhideable {
    pointer-events: none;
    touch-action: none;
  }

  a {
    color: #FFFFFF;
    text-decoration: underline;
    cursor: pointer;
    pointer-events: all;
    touch-action: all;
  }
}
