ul.list {
  padding-left: 2rem;

  &.list-style-disc {
    > li {
      position: relative;
      margin-bottom: 20px;

      &::before {
        content: "";
        top: 0;
        left: -2.5rem;
        width: 32px;
        height: 32px;
        position: absolute;
        background: url('/img/bullet.svg');
      }
    }
  }
}
