#details {
  background-color: white;

  > .container#content {
    background-color: white;
    padding: 0 24px 24px;
    padding-bottom: 100px;
  }

  .title {
    color: #2F3740;
    font-family: 'Proxima Nova Semibold';
    font-size: 22px;
    margin: 0;
  }

  .header-img {
    margin-top: 36px;
    margin-bottom: 36px;
  }

  .details-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    fieldset {
      width: 100%;
    }
  }

  .md-select--NEW_APV_REG_REDESIGN {
    width: 100%;
  }

  .input-field.textfield-box.textfield-box-lg {
    textarea {
      background-color: transparent;
      padding-left: 0px;
      padding-right: 0px;
      border-radius: 0px;
    }
    label.active {
      padding: 0;
    }
  }

  .bra-cup-label {
    color: #2E353D;
    font-family: 'Proxima Nova';
    font-size: 16px;
    font-weight: 400;
  }

  .spaced-top {
    margin-top: 24px;
  }

  .color-switcher-container {
    .color-selection-label {
      font-family: 'Proxima Nova Medium';
      color: #52575B;
      text-align: left;
      font-size: 12px;
      margin-bottom: 8px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .color-swatch {
      & {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 12px;
        padding-left: 10px;
        padding-right: 10px;
      }
      & > div {
        & {
          width: 68px;
          height: 68px;
          flex: 0 1 auto;
          line-height: 50px;
          border-radius: 100%;
          color: #FFFFFF;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;

          @media (min-width: 700px) {
            width: 103px;
            height: 103px;
          }
        }
        .material-icons {
          line-height: inherit;
  
          @media (min-width: 992px) {
            font-size: 64px;
          }
        }
      }
      .brown {
        background: #9E6B4A !important;
      }
      &.hair-color > .brown {
        background: #5A3825 !important;
      }
      .blue {
        background: #A1CAF1 !important;
      }
      .green {
        background: #19A337 !important;
      }
      .grey {
        background: #767694 !important;
      }
      .blonde {
        background: #FBE7A1 !important;
      }
      .black {
        background: #1E2023 !important;
      }
      .red {
        background: #A52A2A !important;
      }
    }
  }

  .footer-button {
    display: flex;

    & > ul {
      margin: auto;
      max-width: 600px;
    }
  }

  .remove-lang-cta {
    color: #707070;
    font-family: 'Proxima Nova Medium';
    font-size: 12px;
  }

  .add-lang-cta {
    color: #DC0064;
    font-family: 'Proxima Nova Medium';
    font-size: 12px;
  }
}
