#client-faq,
#reliability-faq,
#total-rating-faq,
#private-place-faq {
  .grey-box {
    background: #F5F7FA;
    border: 1px solid #E1E6EB;
    height: 152px;
    display: flex;
    border-radius: 8px;
    justify-content: center;
  }
}
