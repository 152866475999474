h1,h2,h3,
h4,h5,h6,
strong {
  @include proxima-font-semibold();
}

*:focus {
  outline: none;
}

html {
  scroll-behavior: smooth;
}
