#feedback {
  .header-content {
    background: #FFF;
    padding: 19px 0;

    .title {
      margin: 0;
      font-size: 26px;
    }

    .rate,
    .review-rating,
    .reviews-counter {
      display: inline-block;
      vertical-align: middle;
    }
    .rate {
      font-size: 16px;
      color: $krypton-grey-darken;
    }
    .reviews-counter {
      font-size: 13px;
    }
  }
  .flex-grid {
    .flex-1 {
      flex: 1;
    }
  }
  .date {
    color: #8A8F95;
    font-size: 14px;
  }
  .reviews-counter {
    margin-top: 2px;
  }
  .rate {
    margin-top: 1px;
  }
  .message {
    position: relative;
    color: #757575;
    font-size: 14px;

    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      height: 50%;
      margin: auto;
      font-size: 12px;
    }
  }
  .review-rating {
    margin: 15px -2px;
    height: 24px;

    .mdi {
      font-size: 18px;
      margin: 0 1px;
      color: #BFB38F;
    }
  }
  .is-private {
    font-size: 12px;
    color: #8A8F95;
  }
  .card-panel {
    margin: 16px 0;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
