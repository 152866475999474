div.intercom-app div:nth-child(2),
div.intercom-app div:nth-child(3) {
  bottom: 150px !important;
}

div.intercom-with-namespace-52k34s {
  bottom: 150px !important;
}

div.intercom-app div div:nth-child(2) {
  bottom: 32px !important;
}

div.intercom-notifications div div div div {
  bottom: 70px !important;
}

div.intercom-notifications div div div div div {
  bottom: 0px !important;
}

div.intercom-2nhm0c {
  bottom: 70px !important;
}

div.intercom-7vd8w5 {
  height: 180px !important;
}

#intercom-container div div div div div div.intercom-7vd8w5.ep19hdq2 div {
  bottom: 70px !important;
}

#intercom-container > div > div > div > div > div > div.intercom-7vd8w5.ep19hdq2 > div {
  bottom: 70px !important;
}

#intercom-container div div div div div div.intercom-7vd8w5.ep19hdq2 {
  height: 180px !important;
}

#intercom-container > div > div > div > div > div > div.intercom-7vd8w5.ep19hdq2 {
  height: 180px !important;
}
