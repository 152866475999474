.switch-container {
  & {
      width: 100%;
      display: table;
  }
  & > div {
      display: table-cell;
      vertical-align: middle;
  }
  & > div:not(.switch-pill) {
      white-space: normal;
  }
  & > div.switch-pill--APV_REG_REDESIGN {
      & {
          white-space: nowrap;
          text-align: right;
      }
      & > span {
          height: 47px;
          line-height: 47px;
          width: 56px;
          display: inline-block;
          color: #DC0064;
          font-size: 16px;
          font-weight: 600;
          text-align: center;
          text-transform: uppercase;
          font-family: 'Proxima Nova Semibold';
          text-align: center;

          @media (min-width: 992px) {
            height: 60px;
            line-height: 60px;
            width: 60px;
          }
      }
      & > span:first-child {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        border-left: 2px solid #CED6E0;
        border-top: 2px solid #CED6E0;
        border-bottom: 2px solid #CED6E0;
      }
      & > span:last-child {
        margin-left: -4px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        border-right: 2px solid #CED6E0;
        border-top: 2px solid #CED6E0;
        border-bottom: 2px solid #CED6E0;
      }
      & > span.active {
        border-bottom: 2px solid #E30074;
        border-top: 2px solid #E30074;
        background-color: #E30074;
        color: #FFFFFF;
      }
      & > span.active:first-child {
          border-left: 2px solid #E30074;
      }
      & > span.active:last-child {
          border-right: 2px solid #E30074;
      }
  }
  & > div.switch-pill {
      & {
          white-space: nowrap;
          text-align: right;
      }
      & > span {
          height: 44px;
          line-height: 42px;
          width: 56px;
          display: inline-block;
          color: #9E9E9E;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          text-transform: uppercase;
          border-bottom: 2px solid #9E9E9E;
          border-top: 2px solid #9E9E9E;
      }
      & > span:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-left: 2px solid #9E9E9E;
      }
      & > span:last-child {
        margin-left: -4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-right: 2px solid #9E9E9E;
        border-left: 1px solid #9E9E9E;
      }
      & > span.active {
        border-bottom: 2px solid #00E676;
        border-top: 2px solid #00E676;
        background-color: #00E676;
        color: #FFFFFF;
      }
      & > span.active:first-child {
          border-left: 2px solid #00E676;
      }
      & > span.active:last-child {
          border-right: 2px solid #00E676;
      }
  }
}

.switch.switch-solid {
  span.lever {
    margin-right: 0;
    width: 47px;
    height: 31px;
    background-color: #ced6e0;

    &::before, &::after {
      width: 27px;
      height: 27px;
      top: 2px;
      left: 2px;
    }

    &::after {
      background-color: white;
    }
  }

  input[type="checkbox"]:checked, input[type="radio"]:checked {
    + span.lever {
      background-color: #06d6a0;

      &::before, &::after {
        left: 18px;
      }

      &::after {
        background-color: #06d6a0;
        background-image: url('/img/icons/ionic-ios-checkmark-circle.svg');
      }
    }
  }
}
