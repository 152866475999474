@import "../../proxima";

.btn {
    font-weight: 600;
    border-radius: 2px;

    @media screen and (max-width: 320px) {
        font-size: 11px;
        padding: 0 11px;
    }
    @media screen and (max-width: 414px) {
        font-size: 11px;
        padding: 0 11px;
    }
}
.btn.display-block {
    @include proxima-font-semibold();
    padding: 0;
}
.btn-secondary {
    line-height: 32px;
}
.btn-secondary:focus,
.btn-secondary:not(:focus) {
    background-color: #FFF;
    border: solid 1px #C51162;
    border-radius: 4px;
    box-shadow: none;
    color: #C51162;
}
.btn.btn-full {
    width: 100%;
}
.btn.btn-round {
  border-radius: 90%;
}
.btn-floating,
.btn-krypton-pink,
.btn-krypton-grey,
.btn-krypton-purple {
    &:disabled {
        background: #dfdfdf!important;
        color: #9f9f9f!important;
    }
    .material-icons {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }
}
.btn-krypton-grey {
    background-color: #959DA6 !important;
}
.btn-floating,
.btn-krypton-pink {
    background: linear-gradient(to left, #E61472, #B30F59);
}
.btn-krypton-purple {
    background: linear-gradient(to left, #7C15D6, #5611C5);
}
.btn.btn-large {
    height: 50px;
    line-height: 0;
}
.btn.btn-large .preloader-wrapper {
    margin: 10px 0;
    width: 30px;
    height: 30px;
}
.btn .preloader-wrapper {
    margin-top: 5px;
    width: 25px;
    height: 25px;
}
