#preview_pre {
    & {
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        position: fixed;
        background-color: #000;
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
    }
    .close,
    .gradient-effect {
        opacity: 1;
        transition: opacity 0.3s;
    }
    .close {
        & {
            top: 16px;
            left: 16px;
            position: absolute;
        }
        i.material-icons {
            font-size: 2rem;
        }
    }
    .gradient-effect {
        width: 100%;
        height: 76px;
        left: 0;
        position: absolute;
    }
    .gradient-effect.top {
        top: 0;
        background: linear-gradient(rgba(#000, 0.75), transparent);
    }
    .gradient-effect.bottom {
        bottom: 0;
        background: linear-gradient(transparent, rgba(#000, 0.75));
    }
    &.no-ui {
        .close,
        .gradient-effect {
            opacity: 0;
        }
    }

    @media (min-width: 992px) {
        & {
            height: 100vh;
            position: absolute;
        }
    }

}

#preview {
  & {
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      position: fixed;
      background-color: #000;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
  }
  .close,
  .gradient-effect {
      opacity: 1;
      transition: opacity 0.3s;
  }
  .close {
      & {
          top: 16px;
          left: 16px;
          position: absolute;
      }
      i.material-icons {
          font-size: 2rem;
      }
  }
  .gradient-effect {
      width: 100%;
      height: 76px;
      left: 0;
      position: absolute;
  }
  .gradient-effect.top {
      top: 0;
      background: linear-gradient(rgba(#000, 0.75), transparent);
  }
  .gradient-effect.bottom {
      bottom: 0;
      background: linear-gradient(transparent, rgba(#000, 0.75));
  }
  &.no-ui {
      .close,
      .gradient-effect {
          opacity: 0;
      }
  }

  @media (max-width: 991px) {
      & {
          margin-top: 56px;
      }
    }

  @media (min-width: 992px) {
      & {
          height: 100vh;
          position: absolute;
      }
  }
}
