#photos-v2,
#photos_pre_A2_2403 {
    & {
        overflow: auto;
        padding-bottom: 24px;
    }
    .photo {
        & {
            width: 49%;
            padding: 32.585% 0;
            position: relative;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 8px;
            border: 1px solid #C4C4C4;
        }
        &.photo:nth-child(odd) {
            float: left;
        }
        &.photo:nth-child(even) {
            float: right;
        }
        .action-top-left,
        .action-top-right {
            & {
                top: 8px;
                padding: 6px;
                border-radius: 100%;
                position: absolute;
                background-color: rgba(color("grey", "darken-4"), 0.75);
            }
            i.material-icons {
                font-size: 1.3rem;
            }
        }
        .action-top-left {
            left: 8px;
        }
        .action-top-right {
            right: 8px;
        }
        .main-photo-label {
            position: absolute;
            width: 80%;
            margin: auto;
            left: 0;
            right: 0;
            bottom: 35px;
            background: color("grey", "darken-4");
            border-radius: 5px;
            color: #FFF;
        }
        .photo-status {
            left: 0;
            bottom: 0;
            width: 100%;
            position: absolute;
            border-radius: 0 0 6px 6px;
            font-weight: bold;
        }
        .add-more {
            & {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 8px;
                position: absolute;
            }
            &::before {
                width: 0;
                content: '';
                height: 100%;
                display: inline-block;
                vertical-align: middle;
            }
            & > div {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
    .primary-upload {
        & {
            width: 100%;
            padding: 50%;
            color: $secondary-color;
            display: block;
            position: relative;
            background-color: rgba(#FF0000, 0.15);
        }
        .primary-upload-content {
            & {
                left: 0;
                top: 50%;
                width: 100%;
                margin-top: -31.5px;
                position: absolute;
            }
            .material-icons {
                font-size: 36px;
            }
        }
    }
}

#photos {
  .photos-register {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: dense;
    outline: none;
    align-items: center;

    @media screen and (min-width: 992px) {
      grid-gap: 16px;
    }

    &.bottom-spacer {
        margin-bottom: 100px;
    }
  }

  .photo {
      width: 160px;
      height: 160px;
      position: relative;
      background-size: cover;
      background-position: center 0;
      background-repeat: no-repeat;
      border-radius: 12px;

      @media screen and (min-width: 992px) {
        width: 224px;
        height: 224px;
        border-radius: 18px;
      }

      &:nth-of-type(odd) {
        justify-self: end;
      }

      &:nth-of-type(even) {
        justify-self: start;
      }

      .primary-photo-indicator {
          position: absolute;
          background-image: url('/img/registration/photos/primary-indicator.svg');
          background-size: 44px;
          height: 44px;
          width: 44px;
          right: -10px;
          top: -10px;
          z-index: 10;

          @media screen and (min-width: 992px) {
            background-size: 50px;
            height: 50px;
            width: 50px;
            right: -15px;
            top: -15px;
          }
      }

      .photo-status {
          left: 0;
          bottom: 0;
          width: 100%;
          position: absolute;
          border-radius: 0 0 6px 6px;
          font-weight: bold;
      }

      &.empty {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23CED6E0FF' stroke-width='5' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
        border-radius: 12px;

        @media screen and (min-width: 992px) {
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='18' ry='18' stroke='%23CED6E0FF' stroke-width='5' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
          border-radius: 18px;
        }
      }

    .add-more {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background-image: url('/img/registration/photos/img-placeholder-new.svg');
        background-size: 30px;
        background-position: center;
        background-repeat: no-repeat;

        @media screen and (min-width: 992px) {
          background-size: 42px;
        }

        .add-btn {
          background-image: url('/img/registration/photos/others-add-btn.svg');
          position: absolute;
          background-size: 44px;
          height: 44px;
          width: 44px;
          right: -10px;
          bottom: -10px;
          z-index: 10;

          @media screen and (min-width: 992px) {
            background-size: 50px;
            height: 50px;
            width: 50px;
            right: -15px;
            bottom: -15px;
          }
        }
    }

      .delete-btn {
        background-image: url('/img/registration/photos/delete.svg');
        position: absolute;
        background-size: 44px;
        height: 44px;
        width: 44px;
        z-index: 10;
        right: -10px;
        bottom: -10px;

        @media screen and (min-width: 992px) {
          background-size: 50px;
          height: 50px;
          width: 50px;
          right: -15px;
          bottom: -15px;
        }
      }
  }

  .description {
    margin-top: 24px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 16px;

    @media screen and (min-width: 992px) {
      margin-top: 80px;
      margin-bottom: 40px;
      font-size: 18px;
    }
  }

  .primary-upload {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: relative;
      cursor: pointer;

      &::after {
        content: " ";
        position: absolute;
        height: 54px;
        width: 54px;
        background-image: url('/img/registration/photos/add-btn.svg');
        right: -18px;
        bottom: -18px;
      }

      .primary-upload-content {
        img {
          width: 30px;
          height: fit-content;

          @media screen and (min-width: 992px) {
            width: 64px;
          }
        }

        div {
          margin-top: 16px;
          color: #2F3740;
          font-size: 16px;

          @media screen and (min-width: 992px) {
            margin-top: 34px;
            font-size: 18px;
          }
        }
      }
  }

  .upload-container {
    height: 160px;
    width: 160px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23CED6E0FF' stroke-width='5' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    border-radius: 12px;
    margin: 0 auto;

    @media screen and (min-width: 992px) {
      height: 340px;
      width: 340px;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23CED6E0FF' stroke-width='5' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
      border-radius: 24px;
    }
  }

  .upload-pill {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 138px;
    margin: 2rem auto;
    padding: 6px 8px;
    border: 1px solid #CED6E0;
    border-radius: 25px;
    cursor: pointer;
    font-family: 'Proxima Nova Semibold';
    color: #2F3740;

    @media screen and (min-width: 992px) {
      padding: 13px 16px;
    }
  }
}

@keyframes shake1 {
    0% {
        transform: rotate(-1deg);
        animation-timing-function: ease-in;
    }
    50% {
        transform: rotate(1.5deg);
        animation-timing-function: ease-out;
    }
}
@keyframes shake2 {
    0% {
        transform: rotate(1deg);
        animation-timing-function: ease-in;
    }
    50% {
        transform: rotate(-1.5deg);
        animation-timing-function: ease-out;
    }
}

#photos-v2 {
  @include proxima-font();
  background-color: white;
  height: 100dvh;

  .page-controls {
      margin-top: 15px;

      span {
          font-size: 14px;
          color: #8A8F95;
      }
      a {
          @include proxima-font-semibold();

          text-transform: uppercase;

          span {
              font-size: 16px;
              color: #C51162;
          }
      }
  }
  .page-controls
  .page-controls span {
      color: #8A8F95;
  }
  .shake-effect:nth-child(2n) .is-draggable,
  .shake-effect:nth-child(2n+1) .is-draggable {
      animation-name: shake2;
      animation-direction: reverse;
      animation-iteration-count: infinite;
      transform-origin: 30% 5%;
  }
  .shake-effect:nth-child(2n) .is-draggable {
      animation-delay: -0.75s;
      animation-duration: .25s;
  }
  .shake-effect:nth-child(2n+1) .is-draggable {
      animation-delay: -0.05s;
      animation-duration: .27s;
  }
  .shake-effect:nth-child(2n-1) .is-draggable {
      animation-name: shake1;
      animation-delay: -0.5s;
      animation-duration: .3s;
      animation-iteration-count: infinite;
      transform-origin: 50% 10%;
  }
  .photo-gallery-v2-photo {
      border-radius: 12px;
      position: relative;
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      transition: box-shadow 0.4s ease-in;
      grid-column: span 1;
      width: 100%;
      line-height: 0;

      &::before {
          content: "";
          display: inline-block;
          width: 1px;
          height: 0;
          padding-bottom: calc(100% / (1/1));
      }
      &.draggable-mirror {
          opacity: 0.7;
      }
      &.empty {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23CED6E0FF' stroke-width='5' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
        border-radius: 12px;

        @media screen and (min-width: 992px) {
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='18' ry='18' stroke='%23CED6E0FF' stroke-width='5' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
          border-radius: 18px;
        }

        .add-more {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          background-image: url('/img/registration/photos/img-placeholder-new.svg');
          background-size: 30px;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      .preview-btn {
          position: absolute;
          width: 100%;
          top: 0;
          height: 100%;
      }
      .primary-photo-indicator,
      .photo-delete,
      .photo-add {
          right: -5px;
      }
      .primary-photo-indicator,
      .photo-delete,
      .edit-locked,
      .photo-add {
          width: 40px;
          height: 40px;
          position: absolute;
          -webkit-tap-highlight-color: transparent;
          z-index: 99;
      }
      .primary-photo-indicator {
          background: url('/img/photos/manage-photos/profile-photo-icon-yellow.svg');
          top: -5px;
      }
      .photo-add,
      .photo-delete {
          cursor: pointer;
          bottom: -5px;
      }
      .photo-delete {
          background-color: white;
          border-radius: 100%;

          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: url('/img/photos/manage-photos/refresh.svg');
            background-repeat: no-repeat;
            background-position: center center;
          }
      }

      &.not-published .photo-delete::before {
        opacity: 0.5;
      }

      .edit-locked {
          background: url('../../img/photos/manage-photos/lock-icon.svg');
          bottom: -5px;
          left: -5px;
      }
      .photo-add {
          background: url('/img/registration/photos/others-add-btn.svg');
      }
      &.draggable-mirror {
          box-shadow: 0px 1px 15px rgba(0,0,0,0.5);

          .photo-delete {
              display: none;
          }
      }
      .photo-insights {
        position: absolute;
        bottom: 8px;
        left: 8px;


        &__like {
          background-color: white;
          width: 50px;
          height: 24px;
          border-radius: 8px;
          color: #2F3740;
          padding: 2px;
          display: flex;
          align-items: center;

          i.material-icons {
            font-size: 20px;
            color: rgb(227, 0, 116);;
          }

          span {
            flex: 1;
            text-align: center;
            font-size: 14px;
          }
        }
      }
  }
  #live-photos {
      margin-top: 32px;

      .photo-container {
          display: grid;
          grid-gap: 8px;
          grid-template-columns: 1fr 1fr;
          grid-auto-flow: dense;
          outline: none;
          padding: 0 30px;
          align-items: center;

          @media screen and (min-width: 768px) {
              grid-gap: 30px;
          }
      }
  }

  .help-btn {
    min-width: 61px;
    padding: 0 16px;
    height: 33px;
    border-radius: 17px;
    background: rgba(48, 56, 65, 0.2);
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 16px;
    font-family: 'Proxima Nova Semibold';

    span {
      color: #2F3740;
      font-size: 14px;
    }
  }
}

#photo-guide {
  @include proxima-font();
  color: #2F3740;

  .title {
      font-style: normal;
      font-size: 22px;
  }
  .sub-title {
      font-size: 18px;
      margin: 0;
  }
  .icons {
      width: 50px;
  }
  .photo-tips {
      padding-top: 50px;
      margin-top: 50px;
      border-top: 1px solid #a6b2bf;

      .tip-title {
        font-size: 22px;
      }
  }
  .case-study {
    font-size: 18px;
    text-align: left;
    margin-bottom: 41px;
  }

  .nav-control {
      display: none;
  }
  .slider-tips {
      min-height: 300px;
      position: relative;
      margin-top: 32px;
      padding-bottom: 50px;

      .carousel {
          height: 274px !important;

          .carousel-wrapper {
              background: #FFF;
              padding: 21px 20px;
              text-align: center;
              color: #2F3740;
              font-size: 18px;
              min-height: 160px;
              position: relative;
              border: 1px solid #CED6E0;
              border-radius: 12px;
              min-height: 216px;

              .carousel-item {
                  min-height: 160px;
                  text-align: center;

                  .tips {
                      margin: 0;
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      width: 90%;
                      color: #2F3740;
                  }
              }
          }
      }

      .carousel .indicators {
        background-color: #2f3740bf;
        width: 72px;
        height: 24px;
        border-radius: 12px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 4px;
      }
  }
  .apv-collapsible {
      border: none;
      box-shadow: none;

      li {
          margin: 34px 0;
      }
      .collapsible-header {
          background: transparent;
          border: none;
          padding: unset;
          font-size: 22px;
          position: relative;
          text-align: left;
          @include proxima-font-semibold();

          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            background: url('/img/photos/guide/chevron-down.svg');
            width: 32px;
            height: 32px;
            transition: transform 0.3s;
            text-align: center;
          }
          &.active::after {
              transform: rotateZ(180deg);
          }
          p {
              word-break: break-word;
              padding-right: 45px;
              margin: 0;
          }
      }
      .collapsible-body {
          font-size: 18px;
          display: block;
          text-align: left;
          padding: unset;
          border: none;
          color: #2F3740;

          .responsive-img {
            margin-top: 23px;
            margin-bottom: 32px;
          }

          p.quote-title {
            margin: 32px 0 16px;

            strong {
              font-size: 20px;
            }
          }

          ul.list {
            padding-left: 3rem;

            &.list-style-disc {
              > li {
                position: relative;
                margin-bottom: 32px;
                font-size: 18px;

                &::before {
                  content: "";
                  top: 0;
                  left: -2.5rem;
                  width: 32px;
                  height: 32px;
                  position: absolute;
                  background: url('/img/bullet-yellow.png');
                }
              }
            }
          }
      }
  }
}

#photos-gallery {
  background-color: white;
  padding-top: 5px;

  .photos-gallery__title {
    text-align: center;
    margin: 0 0 38px;
    font-size: 26px;
    color: #2F3740;
    text-transform: capitalize;
  }
}

#add-primary-photo {
  text-align: center;

  h2.title {
      font-size: 26px;
      margin: 5px 0 32px;
  }

  img {
    width: 90px;
    margin-bottom: 13px;
  }

  p {
    color: #2F3740;
    font-size: 16px;
    line-height: 26px;
    font-family: 'Proxima Nova Semibold';
    margin: 0;
    margin-bottom: 45px;
  }

  span.add-photo-text {
    font-family: 'Proxima Nova Semibold';
    color: #2F3740;
    font-size: 16px;
    position: relative;
    bottom: 32px;
  }

  .photo-gallery-v2-photo.empty {
    margin: 0 auto;
    cursor: pointer;
    width: 160px;
    height: 160px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23CED6E0' stroke-width='5' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    border-radius: 12px;

    @media screen and (min-width: 992px) {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='18' ry='18' stroke='%23CED6E0' stroke-width='5' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
      border-radius: 18px;
    }
  }
  .photo-add {
      right: -5px;
  }
  .photo-add {
      width: 40px;
      height: 40px;
      position: absolute;
      -webkit-tap-highlight-color: transparent;
      z-index: 99;
      cursor: pointer;
      bottom: -5px;
      background: url('/img/registration/photos/others-add-btn.svg');
  }
}

#photo-tips {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .container {
    margin-top: 4rem;
    background-color: transparent !important;
    max-width: 375px;
  }
  .carousel {
      height: 420px !important;

      @media screen and (min-width: 992px) {
        height: calc(70vh - 125px) !important;
      }
  }
  .content-header {
      padding: 15px 15px 0;

      .material-icons {
          font-size: 32px;
      }
  }
  .carousel-img {
      width: 250px !important;
      margin: 2rem auto 0 !important;

      @media screen and (max-width: 320px) {
          width: 180px !important;
      }
  }

  .carousel .indicators {
    background-color: #2f3740bf;
    width: 72px;
    height: 24px;
    border-radius: 12px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly
  }
}

.register-photos-others {
  .protip {
      font-family: 'Proxima Nova Semibold';
      font-size: 16px;
      text-align: center;
      max-width: 320px;
      margin: 0 auto;

      @media screen and (min-width: 992px) {
        font-size: 18px;
    }
  }
}

.crop-container {
  height: auto;
  position: relative;

  .croppie-container {
    position: relative;
  }

  button[class^="crop-controls-btn"] {
    width: 47px;
    height: 47px;
    border-radius: 10px;
    background-color: #CED6E0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    outline: none;

    &.crop-controls-btn__zoom-in {
      background-image: url('/img/photos/manage-photos/zoom-in.svg');

    }

    &.crop-controls-btn__zoom-out {
      background-image: url('/img/photos/manage-photos/zoom-out.svg');
    }
  }
}

#live-photos {
  .pro-tip  {
    font-family: 'Proxima Nova Semibold';
    font-size: 16px;
    color: #2F3740;
    width: 100%;
    text-align: center;
    margin-top: 38px;

    strong {
      color: #E30074;
    }
  }
}
