#availability {
  .availabilities {
    clear: both;
  }
  #content {
    padding-top: 26px;
  }
  .arrow {
    color: #8A8F95;
    float: right;
    margin-left: 5px;
    position: absolute;
    right: 18px;
  }
  .explanation-text {
    background-color: #E1E6EB;
    padding: 16px;
    color: #757575;

    .material-icons {
      color: #00E676;
      -webkit-text-stroke: 1px color("shades", "white");
      display: inline-block;
      vertical-align: top;
    }
    p {
      display: inline-block;
      width: 85%;
      text-align: left;
      vertical-align: top;
      margin: 0;
    }
  }
  select.browser-default {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    border: none;
    color: #8A8F95;
    font-weight: bold;
    font-size: 14px;
    padding: 0 15px 0 0;
    height: 1.5rem;
    position: relative;
    float: right;
    direction: rtl;
    width: 90%;
    max-width: 95px;

    option {
      direction: ltr;
    }
    &:focus {
      outline: none;
    }
    &::-ms-expand {
      display: none;
    }
  }
  .overview-indicator {
    width: 32px;
    height: 32px;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
    background-size: contain;
    background-repeat: no-repeat;

    &.sad {
      background-image: url('../../img/availability/sad-icon.svg');
    }
    &.neutral {
      background-image: url('../../img/availability/neutral-icon.svg');
    }
    &.happy {
      background-image: url('../../img/availability/happy-icon.svg');
    }
  }
  .description {
    font-size: 13px;
    text-align: justify;
  }
  .title-block {
    text-align: justify;
    margin-bottom: 15px;

    .secondary-title {
      display: inline;
      vertical-align: middle;

      @media screen and (max-width: 320px) {
        font-size: 1.1rem !important;
      }
    }
  }

  // Overview Table
  .overview-table {
    border: 1px solid #C4C4C4;
    padding: 17px 17px 25px;
    border-radius: 4px;
    position: relative;

    .overview-table--title,
    .overview-table--schedule {
      display: grid;
      grid-template-columns: repeat(7, 32px);
      grid-gap: 8px;
      justify-content: center;

      @media screen and (max-width: 320px) {
        grid-gap: 6px;
      }
    }
    .overview-table--block {
      height: 32px;
      width: 32px;
      border-radius: 4px;
      position: relative;
    }
    .overview-table--schedule {
      .overview-table--block {
        background: #E0E0E0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        &.filled {
          background-image: url('../../img/availability/ic-status-added.svg');
          background-color: #00e676;
        }
        &.unfilled {
          background-image: url('../../img/availability/ic-status-add.svg');
          background-color: #c51162;
        }
        &.unavailable {
          background-image: url('../../img/availability/ic-status-away.svg');
          background-color: #e0e0e0;
        }
        &.past {
          background-image: url('../../img/availability/ic-status-past.svg');
          background-color: #e0e0e0;
        }
      }
    }
    .overview-table--title {
      .overview-table--block {
        font-size: 11px;
        height: 21px;
      }
    }
  }
  .availability-status {
    background: #E0E0E0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4px;
    min-height: 32px;

    &.filled {
      background-image: url('../../img/availability/ic-status-added.svg');
      background-color: #00e676;
    }
    &.unfilled {
      background-image: url('../../img/availability/ic-status-add.svg');
      background-color: #c51162;
    }
    &.unavailable {
      background-image: url('../../img/availability/ic-status-away.svg');
      background-color: #e0e0e0;
    }
  }
  .expand-table-btn {
    background-color: #F5F5F5;
    background-image: url('../../img/availability/ic-collapse-expand.svg');
    background-position: center;
    width: 25px;
    height: 25px;
    border-radius: 999px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -14px;
    border: 1px solid #C4C4C4;
    transform: rotateZ(180deg);

    &::after {
      content: "";
      width: 60px;
      left: -18px;
      height: 60px;
      margin: auto;
      right: 0;
      bottom: 0;
      top: 0;
      position: absolute;
    }
    &.active {
      transform: rotateZ(0deg);
    }
  }
  .month {
    color: #8A8F95;
  }
}

// Availability Card
.availability-card {
  display: block;
  position: relative;
  width: 100%;
  display: block;

  .availability-link {
    position: absolute !important;
    height: 100% !important;
    width: 100%;
    left: 0;
  }

  &.is-card-active {
    min-height: 196px;
  }

  @media screen and (max-width: 320px) {
    .make-available small,
    .confirm-btn {
      font-size: 0.7rem;
    }
    .confirm-btn {
      padding: 0 0.5rem !important;
    }
  }
  .saved, .saving {
    strong {
      vertical-align: middle;

      @media screen and (max-width: 320px) {
        font-size: 0.7rem;
      }
    }
  }
  .btn-click.disabled {
    color: #E0E0E0;
  }
  .left-btn {
    padding: 6px 0;

    small {
      @media screen and (max-width: 320px) {
        font-size: 0.7rem;
      }
    }
    .material-icons {
      @media screen and (max-width: 320px) {
        font-size: 22px;
      }
    }
  }
  .right-btn .btn {
    padding: 0 1rem;
  }
  .save-indicator {
    padding: 5px 0;

    .material-icons {
      font-size: 26px;

      @media screen and (max-width: 320px) {
        font-size: 24px;
      }
    }
  }
  .month {
    color: #8A8F95;
  }
  .card-panel {
    margin-bottom: 0;
    cursor: pointer;
    overflow: hidden;
    height: 64px;
    display: block;
    padding: 0;
    min-height: 64px;

    &.today {
      .availabilities--date {
        letter-spacing: 0;
      }
      @media screen and (max-width: 320px) {
        .availabilities--date {
          pre {
            overflow: inherit;
            font-size: 10px;
            font-weight: bolder;
          }
        }
        .time {
          flex: 3;
        }
        .indicators {
          flex: 1;
        }
      }
      @media screen and (max-width: 375px) {
        .availabilities--date {
          pre {
            overflow: inherit;
            font-size: 10px;
            font-weight: bolder;
          }
        }
        .time {
          flex: 3;
        }
        .indicators {
          flex: 1;
        }
      }
    }
    &.active {
      height: auto;
    }
  }
  .card-panel--body {
    padding: 0 16px 16px;
    margin-top: 20px;
  }
  .card-panel--header {
    padding: 0 16px 0;
    line-height: 16px;
    height: 64px;
    align-items: center;
    display: flex;
    position: relative;

    > div {
      align-items: center;
      position: relative;
      height: auto;
      min-width: 32px;
      font-weight: 700;
    }

    &.monday,
    &.today {
      .availabilities--date pre {
        font-weight: bolder;
        color: #000;
      }
    }
    &.active {
      .availabilities--date {
        color: #000;
      }
    }
    &.inactive {
      .time {
        color: #8A8F95;

        @media screen and (max-width: 320px) {
          font-size: 14px;
        }
      }
    }
  }
  .availabilities--date,
  .availabilities--status {
    flex: 1 0 15%;
    max-width: 32px;
  }
  .availabilities--summary {
    color: #C51162;
    display: flex;
    flex: 2 0 70%;

    &.not-available {
      .time {
        color: #c4c4c4;
      }
    }
    > div {
      display: inline-block;
      vertical-align: middle;
    }
    p {
      margin: auto;
    }
    .time {
      color: #000;
      font-weight: 700;
      flex: 3;

      @media screen and (min-width: 375px) {
        flex: 3;
      }
      @media screen and (min-width: 414px) {
        flex: 2;
      }
      @media screen and (min-width: 768px) {
        flex: 5;
      }
    }
    .spacer {
      flex: 1;

      @media screen and (max-width: 320px) {
        max-width: 20px;
      }
    }
    .meeting-indicators {
      flex: 1;

      @media screen and (max-width: 320px) {
        width: 20px;
        flex: unset;
      }
      div {
        width: 10px;
        height: 10px;
        margin: auto;
        border-radius: 1000px;
        background: #E0E0E0;

        &.active {
          background: color("krypton-orange", "base");
        }
      }
      .indicator:nth-child(2) {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
    .indicators {
      flex: 1;

      > div {
        display: inline-block;
        height: 1.5rem;
        width: 1.5rem;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0 2px;

        @media screen and (max-width: 320px) {
          margin: 0;
        }
      }
      .out-of-town {
        background-image: url('../../img/availability/ic-out-of-town.svg');
        &.active {
          background-image: url('../../img/availability/ic-out-of-town-active.svg');
        }
        &.disabled {
          background-image: url('../../img/availability/ic-out-of-town-disabled.svg');
        }
      }
      .overnight {
        background-image: url('../../img/availability/ic-overnight.svg');
        &.active {
          background-image: url('../../img/availability/ic-overnight-active.svg');
        }
        &.disabled {
          background-image: url('../../img/availability/ic-overnight-disabled.svg');
        }
      }
    }
  }
  .availabilities--date {
    pre {
      font-family: inherit;
      white-space: pre-line;
      letter-spacing: 1.3px;
      font-size: 13px;
      color: #8A8F95;
      overflow: initial;
    }
    &.today {
      max-width: 45px;
    }
  }
  [disabled].noUi-target, [disabled].noUi-handle, [disabled] .noUi-handle {
    background: #949494 !important;
  }
  .availability-schedule--input .noUi-handle {
    position: relative;

    &::after {
      content: '';
      width: 3rem;
      height: 3rem;
      position: absolute;
      top: 0;
      left: -1rem;
      right: 1rem;
      margin: auto;
      bottom: 0;
      background: transparent;
    }
  }
  .input-group {
    display: flex;
    align-items: center;
    margin: 10px 0;
    margin: 20px 0;
    color: #8A8F95;

    .select-wrapper {
      overflow: hidden;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .away-icon,
    .overnight-icon {
      width: 24px;
      margin-right: 15px;
    }
    .out-of-town-icon {
      width: 14px;
      margin: 0 20px 0 5px;
    }
    img {
      vertical-align: middle;
    }
    > div:nth-child(odd) {
      flex: 2;
      text-align: left;
      font-weight: 700;
    }
    > div:nth-child(even) {
      flex: 1;
      text-align: right;
    }
  }
  .switch label .lever {
    margin: 0;
  }
  .availability-schedule--indicator {
    margin-top: 15px;
    display: flex;

    &.disabled {
      .indicator {
        background: #e0e0e0 !important;
      }
    }
    .indicator {
      height: 20px;
      flex: 1;
      background: #e0e0e0;
      margin: 0 1px;

      @media #{$small-and-down} {
        height: 22px;
      }
      &.prime-time {
        &:after {
          color: #00E676;
          content: "flash_on";
          font-family: "Material Icons";
          font-feature-settings: "liga" 1;
          font-size: 14px;

          @media #{$small-and-down} {
            font-size: 11px;
          }
        }
      }
      &.meeting {
        background: color("krypton-orange", "base");
      }
      &.half-available-meeting-2,
      &.half-available-meeting-1,
      &.half-available-2,
      &.half-available-1,
      &.half-meeting-1,
      &.half-meeting-2,
      &.meeting,
      &.available {
        &.prime-time {
          &:after {
            color: color("shades", "white");
          }
        }
      }
      &.half-meeting-2 {
        background: linear-gradient(to right, color("krypton-orange", "base") 49.9%, #e0e0e0 49.9%);

      }
      &.half-meeting-1 {
        background: linear-gradient(to right, #e0e0e0 49.9%, color("krypton-orange", "base") 49.9%);
      }
      &.available {
        background: #00E676;
      }
      &.half-available-2 {
        background: linear-gradient(to right, #00E676 49.9%, #e0e0e0 49.9%);
      }
      &.half-available-1 {
        background: linear-gradient(to right, #e0e0e0 49.9%, #00E676 49.9%);
      }
      &.half-available-meeting-2 {
        background: linear-gradient(to right, color("krypton-orange", "base") 49.9%, #00E676 49.9%);
      }
      &.half-available-meeting-1 {
        background: linear-gradient(to right, #00E676 49.9%, color("krypton-orange", "base") 49.9%);
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .preloader-wrapper {
    width: 25px;
    height: 25px;
    vertical-align: middle;
  }
  .material-icons {
    vertical-align: bottom;
  }
  input[type=checkbox] {
    &:checked + .lever {
      background-color: #79ddb4;

      &:after {
        background-color: #00e676;
      }
    }
  }
  input[type=checkbox][disabled] {
    &:checked + .lever {
      &:after {
        background-color: #949494;
      }
    }
  }
  .lever {
    &:checked {
      background-color: #00e676;
    }
  }
  .input-field {
    &.valid {
      .select-dropdown {
        border-bottom: 2px solid #00e676;
        margin-bottom: 0;
      }
    }
    &.invalid {
      .select-dropdown {
        border-bottom: 2px solid #c51162;
        margin-bottom: 0;
      }
    }
  }
  .switch input[type=checkbox][disabled] + .lever {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}
