$FEATURE_A2_2719: true;
$FEATURE_NEW_API: true;

@import "materialize/materialize";
@import "_envvars";
@import "proxima";

@import "./krypton/index";

@import "helpers/variables";
@import "helpers/layout";
@import "helpers/display";
@import "helpers/border";
@import "helpers/transitions";
@import "helpers/flex-grid";
@import "helpers/add-to-home";
@import "helpers/install-chrome-banner";
@import "helpers/list";
@import "helpers/_add-to-home";
@import "helpers/status-indicator";
@import "helpers/utils";
@import "helpers/intercom";

@import "pages/app";
@import "pages/availability";
@import "pages/login";
@import "pages/account";
@import "pages/dashboard";
@import "pages/profile";
@import "pages/photos";
@import "pages/preview";
@import "pages/crop";
@import "pages/anonimize";
@import "pages/register";
@import "pages/register-new";
@import "pages/events";
@import "pages/details";
@import "pages/support";
@import "pages/feedback";
@import "pages/video-date-landing-page";
@import "pages/faq";
