.flex-grid {
    & {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
    }
    &.space-between {
        justify-content: space-between;
    }
    &.justify-content-center {
        justify-content: center;
    }
}
.flex-1 {
    flex: 1;
}
.flex-2 {
    flex: 2;
}
.flex-grid-column {
    & {
        display: flex;
        flex-direction: column;
    }
}
.flex-grid > .grid-item {
    & {
        flex: 0 1 auto;
        flex-basis: 100%;
        padding: 0 8px;
    }
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
    }
}

$sizes: (
    1:  8.33%,
    2:  16.66%,
    3:  25.00%,
    4:  33.00%,
    5:  41.66%,
    6:  49.50%,
    7:  58.33%,
    8:  66.66%,
    9:  75.00%,
    10: 83.33%,
    11: 91.66%,
    12: 100.00%,
);

@each $breakpoint_name, $breakpoint in $breakpoints {
    @each $size_name, $size in $sizes {
        @if $breakpoint_name == "sm" {
            .grid-item.f-#{$breakpoint_name}-#{$size_name} {
                flex-basis: $size;
            }
            .grid-item.o-#{$breakpoint_name}-#{$size_name} {
                order: $size_name;
            }
        }
        @else {
            @media (min-width: $breakpoint) {
                .grid-item.f-#{$breakpoint_name}-#{$size_name} {
                    flex-basis: $size;
                }
                .grid-item.o-#{$breakpoint_name}-#{$size_name} {
                    order: $size_name;
                }
            }
        }
    }
}
