.side-nav {
  #sidenav-close {
    padding: 0 32px;
    height: 56px;
    line-height: 56px;
    color: #8A8F95;
    cursor: pointer;

    .material-icons {
        line-height: 56px;
    }
  }
  #sidenav-user {
      padding: 25px 32px;
      font-size: 1.375rem;

      @media screen and (max-width: 320px) {
        padding-top: 0;
        padding-bottom: 0;
      }
  }
  &.side-nav--v2 {
    .router-link {
      height: 52px;
      font-size: 20px;
    }
    .sub-links {
      li {
        .router-link {
          font-size: 16px;
        }
        padding-left: 44px;
      }
    }
  }
  .menu-group {
    .drop-icon {
      margin-right: 0 !important;
    }
    &.menu-group--expanded {
      .drop-icon {
        transform: rotateZ(180deg);
      }
      .sub-links {
        display: block;
      }
    }
    .sub-links {
      display: none;
    }
  }
  li:not(.menu-group):not(.nav-user):not(.nav-control):hover{
    background-color: rgba(0, 0, 0, 0.05);
  }
}

#sidenav {
    @include proxima-font();

    .inbox-menu {
      a.router-link {
        display: flex;

        strong {
          flex: 1
        }
        .material-icons {
          flex: 0
        }
      }
    }
    .feature-lock-indicator {
      .mdi,
      .material-icons {
        color: #cad1d9 !important;
      }
      .icon {
        opacity: 0.6 !important;
      }
      &::before {
        position: absolute !important;
        left: 45px !important;
        bottom: 11px !important;
        z-index: 10 !important;
      }
    }
    .profile-icon {
      background-image: url('../../../img/menu/ic-profile.svg');
    }
    .availabilities-icon {
      background-image: url('../../../img/menu/ic-availabilities.svg');
    }
    .events-icon {
      background-image: url('../../../img/menu/ic-events.svg');
    }
    .settings-icon {
      background-image: url('../../../img/menu/ic-settings.svg');
    }
    .support-icon {
      background-image: url('../../../img/menu/ic-support.svg');
    }
    i.mdi {
      font-size: 24px;
      margin-right: 20px;
    }
}

#sidenav.side-nav.side-nav--super-provider {
  li.nav-user {
    #sidenav-user {
      display: flex;
      align-items: center;

      img {
        height: 24px;
        margin-left: 8px;
      }
    }
  }
  li {
    a {
      i.material-icons {
        color: #BFB38F !important;
      }
    }
  }
}

.side-nav li > a {
    @include proxima-font-semibold();

    &:hover { background-color: transparent;}
    &.lilac {
      &::after {
          right: 40px;
          top: 19px;
          left: initial;
      }
    }
    &.lilac-left {
      &::before {
        left: 16px;
        top: 19px;
        right: initial;
      }
    }
    strong {
      vertical-align: middle;
    }
    i {
        color: #78838F !important;
    }
    .icon {
        margin-right: 15px;
        padding: 0px 4px;
        height: 24px;
        width: 24px;
        background-repeat: no-repeat;
        display: inline-block;
        vertical-align: middle;
        background-position: center;
    }
    .material-icons {
        margin-right: 21px !important;
    }
}
