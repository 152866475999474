@charset "UTF-8";

// Material icons
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// Colors
@import "components/color";

// Variables;
@import "components/variables";

// Reset
@import "components/normalize";

// components
@import "~materialize-css/sass/components/global";
@import "~materialize-css/sass/components/badges";
@import "~materialize-css/sass/components/icons-material-design";
@import "~materialize-css/sass/components/grid";
@import "~materialize-css/sass/components/navbar";
@import "~materialize-css/sass/components/typography";
@import "~materialize-css/sass/components/transitions";
@import "~materialize-css/sass/components/cards";
@import "~materialize-css/sass/components/toast";
@import "~materialize-css/sass/components/tabs";
@import "~materialize-css/sass/components/tooltip";
@import "~materialize-css/sass/components/buttons";
@import "~materialize-css/sass/components/dropdown";
@import "~materialize-css/sass/components/waves";
@import "~materialize-css/sass/components/modal";
@import "~materialize-css/sass/components/collapsible";
@import "~materialize-css/sass/components/chips";
@import "~materialize-css/sass/components/materialbox";
@import "~materialize-css/sass/components/forms/forms";
@import "~materialize-css/sass/components/table_of_contents";
@import "~materialize-css/sass/components/sideNav";
@import "~materialize-css/sass/components/preloader";
@import "~materialize-css/sass/components/slider";
@import "~materialize-css/sass/components/carousel";
@import "~materialize-css/sass/components/tapTarget";
@import "~materialize-css/sass/components/pulse";
@import "~materialize-css/sass/components/date_picker/default";
@import "~materialize-css/sass/components/date_picker/default.date";
@import "~materialize-css/sass/components/date_picker/default.time";

// Materialize extras
@import "~materialize-css/extras/noUiSlider/nouislider.css";

// Custom components as add-ons for materialize
@import "components/toast";

@import "custom/typography";
@import "custom/forms";
@import "custom/buttons";
@import "custom/navbar";
@import "custom/sidenav";
@import "custom/input_helper";
@import "custom/textfield_box";
@import "custom/modal";
@import "custom/nouislider";
@import "custom/switch";
@import "custom/slide-to-unlock";


// Custom Overrides
.toast {
  word-break: break-word !important;
}
.card-panel {
  border-radius: 4px;
  -webkit-box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
  box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
}
.krypton-purple-checkbox {
  & + label:before {
    border: 2px solid #5611C5;
  }
  &:checked + label:before {
    border-right: 2px solid #00E676;
    border-bottom: 2px solid #00E676;
  }
}
