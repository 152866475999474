.add-to-home {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  text-align: center;
  color: #959DA6;
  padding: 1vh 0;
  box-sizing: border-box;
  background-color: #000;
  z-index: 9998;
  background-color: #F7FAFC;

  .instruction-install {
    width: 80%;
    margin: auto;
  }
  .title {
    .app-icon,
    .app-details {
      display: inline-block;
      vertical-align: middle;
    }
    .app-icon {
      width: 65px;
      height: 65px;
      margin-right: 10px;
    }
    .app-details {
      width: 49%;
      text-align: left;

      h5 {
        color: #2F3740;
      }
      p {
        color: #8A8F95;
        margin-top: 0;
      }
    }
  }
  .gif-guide {
    min-width: 100px;
    width: 130px;
    height: 125px;
    margin: auto;

    img {
      border: 2px solid #2F3740;
    }
  }
  .instruction {
    position: absolute;
    bottom: calc(19vh);
    left: 0;
    right: 0;
    margin: auto;
    width: 90%;
  }
  .overlay {
    height: 210px;
    position: absolute;
    bottom: 0;
    padding: 10px 20px 20px;
    border-top: 1px solid #EEE;
    box-shadow: 0 -3px 6px rgba(0,0,0,0.16);

    .btn {
      width: 90%;
    }
  }

  &.android-banner {
    .instruction {
      bottom: calc(37vh);
    }
  }
}
.blur {
  position: absolute;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  transition: 0.2s filter linear;
  -webkit-transition: 0.2s -webkit-filter linear;
  width: 100%;
}
.add-to-home.ios .gif-guide,
.add-to-home.android .gif-guide {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.add-to-home.ios .gif-guide {
  width: 24vh;
  height: 65vh;
}
.add-to-home.android .gif-guide {
  width: 27vh;
  height: 60vh;
}
.add-to-home.android .instruction-text .title {
  position: absolute;
  width: 100%;
  bottom: calc(19vh);
}
.add-to-home.android .browser-preview {
  margin-top: 40px;
  text-decoration: underline;
  opacity: 0.8;
  text-align: center;
}
.add-to-home .browser-preview {
  margin: -45px 0 40px;
  text-decoration: underline;
  opacity: 0.8;
  text-align: right;
}
.add-to-home .logo-name-container {
  background-repeat: no-repeat;
  background-position: center 0;
  height: 35vh;
  margin: 0 45px;
  background-size: contain;
  font-size: 24px;
}
.add-to-home.android .logo-name-container {
  background-repeat: no-repeat;
  background-position: center 0;
  height: 30vh;
  margin: 80px 45px 0;
  background-size: contain;
  font-size: 24px;
}
.add-to-home.android .homescreen-text {
  padding-top: 0;
  line-height: 1.5;
  font-size: 16px;
}
.add-to-home .homescreen-text {
  line-height: 1.5;
  font-size: 16px;
}
.add-to-home .icon-addToHome {
  width: 25px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
}
.add-to-home .icon-homePointer {
  margin-top: 1vh;
  background: url('/img/pwa/ic-arrow-down.svg') no-repeat top left;
  background-position: center;
  width: 100%;
  height: 30px;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: topToBottom;
  animation-name: topToBottom;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}
.add-to-home.android .icon-addToHome {
  vertical-align: text-bottom;
  width: 35px;
  height: 35px;
  display: inline-block;
  background: url('/img/pwa/mobile-sprite.png') no-repeat top left;
  background-size: cover;
  background-position: center -115px;
  margin: 0;
  vertical-align: middle;
}
.add-to-home.android .icon-homePointer {
  background: url('/img/pwa/ic-arrow-up.svg') no-repeat;
  background-position: right;
  width: 95vw;
  height: 45px;
}

@keyframes topToBottom {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, 20px);
  }
}

@keyframes bottomToTop {
  from {
    transform: translate(0, 0) rotate(180deg);
  }
  to {
    transform: translate(0, 20px) rotate(180deg);
  }
}