.rotate-opposite {
  transform: rotateY(180deg);
}

.lilac {
  position: relative;

  &::after {
      content: "";
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background-color: #c4c4c4;
      position: absolute;
      left: -12px;
      top: 5px;
  }
  &.lilac--green {
      &::after {
      background-color: #00e676;
      }
  }
  &.lilac--red {
      &::after {
          background-color: #c51162;
      }
  }
}

.lilac-left {
  position: relative;

  &::before {
      content: "";
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background-color: #c4c4c4;
      position: absolute;
      left: -12px;
      top: 5px;
  }
  &.lilac--green {
      &::before {
        background-color: #00e676;
      }
  }
  &.lilac--red {
      &::before {
          background-color: #c51162;
      }
  }
}

.no-margin {
  margin: 0;
}

.container--flex {
  display: flex;

  &.container--flex-direction-column {
    flex-direction: column;
  }
  &.container--flex-wrap {
    flex-wrap: wrap;
  }
  &.container--flex-align-item-start {
    align-items: flex-start;
  }
  &.container--flex-align-item-center {
    align-items: center;
  }
  &.container--flex-justify-center {
    justify-content: center
  }
  @for $i from 0 through 5 {
    .flex-#{$i} {
      flex: $i;
      padding: 16px;
    }
  }
}

.blur-content {
  filter: blur(3px);
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -o-user-select: none;
  -moz-user-select: none;
}

.rtl {
  direction: rtl;
}

.preloader-wrapper {
  &.extra-small {
    width: 22px;
    height: 22px;
    vertical-align: middle;
  }
}
.flex-2 {
  flex: 2;
}
