.input-field {
  label {
    color: #2F3740;
  }
  & {
      width: 100%;
      display: inline-block;
  }
}

textarea.materialize-multiline {
    background: #FFF;
    height: 1rem;
    resize: none;
    border: none;
    outline: none;
    border-bottom: $input-border;
    margin: $input-margin;
    padding: 0.75rem 0 0.75rem;
    box-shadow: none;
    box-sizing: content-box;
    transition: all 0.3s, height 0s;

    // Disabled input style
    &:disabled,
    &[readonly="readonly"] {
      color: $input-disabled-color;
      border-bottom: $input-disabled-border;
    }

    // Disabled label style
    &:disabled+label,
    &[readonly="readonly"]+label {
      color: $input-disabled-color;
    }

    // Focused input style
    &:focus:not([readonly]) {
      border-bottom: 1px solid $input-focus-color;
      box-shadow: 0 1px 0 0 $input-focus-color;
    }

    // Focused label style
    &:focus:not([readonly])+label {
      color: $input-focus-color;
    }

    // Valid Input Style
    &.valid,
    &:focus.valid {
      @extend %valid-input-style;
    }

    // Custom Success Message
    &.valid + label:after,
    &:focus.valid + label:after {
      @extend %custom-success-message;
    }

    // Invalid Input Style
    &.invalid,
    &:focus.invalid {
      @extend %invalid-input-style;
    }

    // Custom Error message
    &.invalid + label:after,
    &:focus.invalid + label:after {
      @extend %custom-error-message;
    }

    // Full width label when using validate for error messages
    &.validate + label {
      width: 100%;
    }

    // Form Message Shared Styles
    & + label:after {
      @extend %input-after-style;
    }

    // TODO: Remove once input fields are reworked to support validation messages better
    &.invalid + label:after,
    &.valid + label:after{
      display: none;
    }

    &.invalid + label.active:after,
    &.valid + label.active:after{
      display: block;
    }
}

.dropdown-content li > span > [type="checkbox"] + label {
    display: inline;
}

.textfield-box-purple {
  & > textarea,
  & > input {
    border-bottom: 2px solid color("krypton-purple", "base") !important;
  }
}

.textfield-box-pink {
  & > textarea,
  & > input {
    border-bottom: 2px solid color("krypton-pink", "base") !important;
    border-radius: 4px !important;
  }
}
.textfield-label-pink {
  & > label {
    color: color("krypton-pink", "base") !important;
  }
}

.textfield-box-large textarea {
  min-height: 96px;
}

.mdi-checkbox-pink {
  & + label {
    padding-left: 30px;
    text-align: left;
  }
  & + label:before,
  &:not(.filled-in) + label:after {
    font: normal normal normal 24px/1 "Material Design Icons";
    border: none;
    color: color("krypton-pink", "base");
    content: "\F0131";
    transform: none;
    top: -1px;
  }
  &:checked + label:after {
    content: "";
  }
  &:checked + label:before {
    font: normal normal normal 24px/1 "Material Design Icons";
    border: none;
    color: color("krypton-pink", "base");
    content: "\F0132";
    transform: none;
    top: -1px;
    left: 0;
  }
}
