html {
    font-size: 16px !important;
    font-family: 'Proxima Nova';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.xsmall {
    font-size: 0.75rem;
}
small,
.small {
    font-size: 0.875rem;
}
strong,
.strong {
    font-weight: 700;
}
.xstrong {
    font-weight: 900;
}

.capitalize {
    text-transform: capitalize;
}
.lowercase {
    text-transform: lowercase;
}
.uppercase {
    text-transform: uppercase;
}

.underline {
    text-decoration: underline;
}

.clickable {
    cursor: pointer;
    // Disable highlighting of text when tapped
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
    -webkit-tap-highlight-color: transparent;
}
