#anonimize {
  .anonymize-container {
    width: 100%;
    position: relative;
    text-align: center;

    &.--loading {
      background-color: #dfe0f4;
      min-height: 500px;
    }

    @media (min-width: 992px){
      width: 375px;
      margin: 0 auto;
    }

    & > img {
      max-width: 100%;
      max-height: 100%;
      display: inline-block;
      vertical-align: middle;
      min-width: 375px;
      aspect-ratio: 3/4;
    }

    &::before {
      content: '';
      width: 0;
      height: 100%;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .slider-label {
    text-transform: uppercase;
  }

  .disabled-slider {
    text-decoration: underline;
  }

  #overlaySliderContainer {
    padding-top: 1.5rem;

    .noUi-horizontal {
      height: 4px;
      border-radius: 2px;
      max-width: 269px;
      margin: 0 auto;
    }

    .noUi-connect {
      background-color: #E30074;
      border-radius: 2px;
    }

    .noUi-horizontal .noUi-handle, .noUi-vertical .noUi-handle {
      width: 30px;
      height: 30px;
      top: -15px;
      background-color: #E30074;
    }
  }
}
