.valign-middle,
.valign-middle>span,
.valign-middle .material-icons {
    & {
        margin-top: -3px;
        display: inline-block;
        vertical-align: middle;
    }
}

.valign-bottom {
    vertical-align: bottom;
}

.display-block {
    width: 100%;
    display: block;
}

.display-inline {
    width: auto;
    display: inline;
}

.display-inline-block {
    width: auto;
    display: inline-block;
}

@each $breakpoint_name,
$breakpoint in $breakpoints {
    @media (min-width: $breakpoint) {
        .display-#{$breakpoint_name}-block {
            width: 100%;
            display: block;
        }
        .display-#{$breakpoint_name}-inline {
            width: auto;
            display: inline;
        }
        .display-#{$breakpoint_name}-inline-block {
            width: auto;
            display: inline-block;
        }
    }
}

.margin-bottom {
    margin-bottom: 20px;
}

#pwa-update-available {
    position: fixed;
    width: 100%;
    height: 100%;
    bottom: 0;
    padding: 40vh 16px;
    text-align: center;
    background: rgba(0,0,0,0.8);
    color: color("shades", "white");
    z-index: 9999;

    .pwa-update-container {
        background: #FFFFFF;
        color: #2F3740;
        font-size: 20px;
        font-family: 'Proxima Nova Semibold';
        border-radius: 12px;
        padding-top: 32px;
        padding-bottom: 32px;
        max-width: 350px;
        margin: auto;

        .pwa-update-text-container {
            padding-left: 24px;
            padding-right: 24px;
        }
        
        .pwa-update-btn-container {
            & {
                margin-top: 16px;
                border-top: 1px solid #CAD1D9;
                padding-top: 16px;
            }

            .btn {
                margin-top: 0 !important;
                margin-bottom: 0 !important;
                text-transform: none !important;
                background: transparent linear-gradient(261deg, #FF0073 0%, #DC0064 100%) 0% 0% no-repeat padding-box;
                border-radius: 10px;
                color: #FFFFFF;
                font-size: 16px;
                height: 46px;
                box-shadow: none;
                min-width: 295px;
            }
        }
    }
}

.no-select {
    -webkit-tap-highlight-color: transparent !important;
}

.tooltip {
    display: flex;
    align-items: center;
    align-content: center;
    text-align: center;
    justify-content: center;

    @media (max-width: 360px) {
        align-items: inherit;
    }
}
