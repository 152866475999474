.slide-to-unlock {
  position: relative;
  width: 100%;

  .preloader-wrapper {
    position: absolute;
    z-index: 1000;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
  }
  &.reversed {
    label {
      padding-right: 0;
      margin-right: 12%;
      width: 89%;
    }
  }
  label {
    font-size: 16px;
    color: #89949c;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding-right: 20%;
    width: 80%;
    height: 100%;
    right: 0;
    z-index: 10;

    @media #{$small-and-down} {
        width: 85%;
        padding-right: 15%;
    }
  }

  @mixin track($color: red, $radius: 0) {
    &::-webkit-slider-runnable-track {
        @content;
        width: 100%;
        border-radius: $radius;
        background-color: $color;
        animate: 0.2s;
    }
    &::-moz-range-track {
        @content;
        width: 100%;
        border-radius: $radius;
        background-color: $color;
        animate: 0.2s;
    }
    &::-moz-focus-outer {
        border: 0;
    }
    &::-ms-track {
        border: 0;
        @content;
        width: 100%;
        border-radius: $radius;
        background-color: $color;
        color: transparent;
        animate: 0.2s;
    }
    &::-ms-fill-lower, &::-ms-fill-upper {
        background-color: transparent;
    }
    &::-ms-tooltip {
        display: none;
    }
  }

  @mixin thumb() {
    &::-webkit-slider-thumb {
        position: relative;
        margin-top: 0;
        z-index: 1;
        appearance: none;
        @content;
    }
    &::-moz-range-thumb {
        position: relative;
        z-index: 1;
        border: 0;
        @content;
    }
    &::-ms-thumb {
        position: relative;
        z-index: 1;
        @content;
    }
  }

  .thumb {
    display: none;
  }
  .pullee {
    width: 100%;
    appearance: none;
    position: relative;

    @include thumb() {
        background: url('/img/events/slide-active.png') no-repeat;
        background-size: cover;
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
    }

    // accessibility
    &:focus {
        outline: none;
    }
    &.reversed {
        @include thumb() {
            -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
        }
    }

    // thumb styles
    @include thumb() {
        display: block;
        width: 57px;
        height: 57px;
        // border-radius: 50%;
        background-size: contain;
        -webkit-transition: transform ease-out 100ms;
        transition: transform ease-out 100ms;
        cursor: -webkit-grab;
        cursor: -moz-grab;
        z-index: 999;
    }

    // track styles
    @include track(color("shades", "white"), 2rem) {
        height: 56px;
    }
    &:enabled {
        @include track(color("shades", "white"), 2rem) {
            height: 56px;
        }
    }
    &:active {
        @include track(color("shades", "white"), 2rem) {
            height: 56px;
        }
    }

    // handle disabled state
    &:disabled {
        opacity: 1 !important;

        @include thumb() {
            cursor: no-drop;
            background: url('/img/events/slide-locked.png') no-repeat;
            background-size: cover;
        }
        @include track(#E1E6EB, 2rem) {
            height: 56px;
        }
    }
  }

}
