#crop {
  .cr-slider-wrap {
      margin: 0;
  }
  .crop-controls {
      position: absolute;
      top: 0;
      z-index: 99;
      right: 25px;
      height: 50px;
      width: 30px;
      bottom: 0;
      margin: auto;

      button {
          display: block;
          padding: 0;
          margin: 3px 0;
      }
  }
}
