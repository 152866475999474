#profile {
    .container {
        background: #FFFFFF;
        padding-bottom: 110px;
    }
    #primary-photo {
        & {
            position: relative;
        }
        & > img {
            max-width: 100%;
            display: block;
            margin: auto;
            border-bottom-left-radius: 20px !important;
            border-bottom-right-radius: 20px !important;
            overflow: hidden;
        }
        .manage-photo-container {
            & {
                position: relative;
            }

            #manage-photos {
                padding: 26px 32px;
                right: 0;
                bottom: 0;
                position: absolute;
                color: color("shades", "white");

                &.floating-cta {
                    border-radius: 50%;
                    background: #DC0064;
                    width: 60px;
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    right: 16px;
                    bottom: -20px;

                    & > .material-icons {
                        font-size: 24px;
                    }
                }
            }
        }
        & > .empty-photo-placeholder {
            background: #FFFFFF;
            border: 1px dashed #CED6E0;
            border-radius: 0px 0px 20px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            min-height: 495px;
            justify-content: center;
            font-family: 'Proxima Nova Semibold';
            color: #2F3740;
            font-size: 16px;

            & > .title {
                font-size: 26px;
            }

            & > .add-photo-img {
                margin-top: 32px;
                height: 90px;
                width: 90px;
            }

            & > .description {
                margin-top: 12px;
                max-width: 320px;
                text-align: center;
            }

            & > .add-photo-icon-container {
                margin-top: 50px;
                display: flex;
                align-items: center;
                flex-direction: column;

                & > .add-photo-icon {
                    height: 30px;
                    width: 30px;
                }

                & > .add-photo-icon-desc {
                    margin-top: 16px;
                }
            }
        }
    }
    .edit-button > a {
        text-decoration: underline;
        color: #DC0064;
        font-family: 'Proxima Nova Semibold';
        font-size: 18px;
    }
    #provider-vitals {
        & {
            padding: 40px 24px;
            color: #2F3740;
        }
        h1#nameAge {
            font-family: 'Proxima Nova Bold';
            margin: 0 0 8px;
            font-size: 30px;
            text-transform: capitalize;
            color: #2F3740;
        }
        h2#cityName {
            font-family: 'Proxima Nova Medium';
            margin: 0;
            font-size: 18px;
            color: #52575B;
        }
        p#profile-motto {
            & {
                font-family: 'Proxima Nova Medium';
                margin: 0;
                margin-top: 32px;
                font-size: 20px;
                text-align: center;
            }
            &.enquote::before {
                content: '\0201C';
            }
            &.enquote::after {
                content: '\0201D';
            }
        }
    }
    .details-container  {
        .title {
            font-family: 'Proxima Nova Semibold';
            color: #52575B;
            font-size: 18px;
        }

        table {
            & {
                font-family: 'Proxima Nova';
                color: #52575B;
                font-size: 16px;
            }
            tr {
                & {
                    background: transparent;
                    border-top: 1px dashed #CED6E0;
                    border-bottom: 1px dashed #CED6E0;
                }
                &:first-child {
                    border-top: none;
                }
                &:last-child {
                    border-bottom: none;
                }
                td {
                    padding: 18px 4px;
                    vertical-align: top;
                    word-break: break-word;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    width: 1px;
                }
                td:first-child {
                    font-family: 'Proxima Nova Semibold';
                }
            }
        }
    }
    div#provider-interview {
        & {
            padding-top: 12px;
        }
        .title {
            font-family: 'Proxima Nova Semibold';
            color: #52575B;
            font-size: 18px;
        }
        .placeholder {
            font-family: 'Proxima Nova Medium';
            font-size: 16px;
            color: #2F3740;
            padding: 16px 12px;
            border-radius: 12px;
            background: #F7FAFF;
        }
        .interview {
            & {
                margin-bottom: 24px;
                color: #2F3740;
                font-size: 18px;
                font-family: 'Proxima Nova';
            }
            .interview-question {
                margin-bottom: 16px;
                font-family: 'Proxima Nova Bold';
                font-size: 20px;
            }
        }
        .add-interview-cta {
            background: transparent linear-gradient(82deg, #DC0064 0%, #FF0073 100%);
            height: 47px;
            width: 100%;
            border-radius: 10px;
            font-family: 'Proxima Nova Semibold';
            font-size: 16px;
            color: #FFFFFF;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 16px;
            cursor: pointer;
        }
    }
    div#provider-preferences {
      .provider-preferences-title {
        color: #52575B;
        font-family: 'Proxima Nova Semibold';
        font-size: 18px;
        text-align: left;
        margin-top: 70px;
      }

      .provider-preferences-placeholder {
        background: #F7FAFF;
        border-radius: 12px;
        text-align: center;
        color: #2F3740;
        font-family: 'Proxima Nova Medium';
        font-size: 16px;
        padding: 12px 16px;
        margin-top: 16px;
      }

      .spp-items-container {
        margin-top: 16px;
        padding-left: 48px;
        padding-right: 48px;

        .spp-item {
          color: #52575B;
          font-family: 'Proxima Nova';
          font-size: 16px;
          padding-top: 18px;
          padding-bottom: 18px;
          border-bottom: 1px dashed #CED6E0;

          &:last-child {
            border-bottom: none;
          }
        }
      }

      .provider-preferences-cta {
        background: transparent linear-gradient(82deg, #DC0064 0%, #FF0073 100%) 0% 0% no-repeat padding-box;
        border-radius: 10px;
        width: 100%;
        height: 47px;
        text-align: center;
        color: #FFFFFF;
        font-family: 'Proxima Nova Medium';
        border: none;
        font-size: 16px;
        margin-top: 16px;
      }
    }
    .margin-top-12 {
        margin-top: 12px;
    }
    .add-motto-cta {
        background: transparent linear-gradient(82deg, #DC0064 0%, #FF0073 100%);
        height: 47px;
        width: 100%;
        border-radius: 10px;
        font-family: 'Proxima Nova Semibold';
        font-size: 16px;
        color: #FFFFFF;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        margin-bottom: 32px;
        cursor: pointer;
    }
}
