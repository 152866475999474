@import "../../proxima";

.modal {
    & {
        border-radius: 8px;
        max-width: 568px;
        top: 50% !important;
        transform: scalex(1) translate(0, -50%) !important;
        transition: top 0.2s, opacity 0.2s;
        width: 100%;
    }
    @media screen and (max-width: 600px) {
        margin: 0 16px;
        width: 94%;
    }
    @media screen and (max-width: 420px) {
        width: 92%;
    }
    &.photo-error-modal {
      background-color: color("shades", "white");
      border-radius: 12px;

      .modal-content {
        padding: 32px 24px 17px;
        text-align: center;
      }
      .status-label {
        font-size: 20px;
        font-family: 'Proxima Nova Semibold';
        margin-bottom: 24px;
        color: #2F3740;
        margin: 0 0 24px;
      }
      .status-text {
        font-size: 18px;
        color: #2F3740;
        margin: 0 0 17px;
      }
      .modal-footer {
        height: 95px;
        padding: 17px 24px 0;
        border: 1px solid #CAD1D9;
        background-color: white;

        .btn {
          background: transparent linear-gradient(261deg, #FF0073 0%, #DC0064 100%) 0% 0% no-repeat padding-box;;
          border-radius: 10px;
          height: 47px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Proxima Nova Semibold';
          text-transform: uppercase;
          font-size: 16px;
          box-shadow: none;
          border: none;
          margin: 0;
        }
      }
    }
    .modal-title {
        font-weight: 700;
        font-size: 1.375rem;
    }
    &.modal-dropdown {
        .modal-header {
            padding: 24px;
            margin-bottom: 0;
            font-weight: 700;
            font-size: 1.125rem;
            border-bottom: 1px solid rgba(#000, 0.1);
            background-color: color("shades", "white");

            @media (min-width: 361px) {
                font-size: 1.375rem;
            }
        }
        .modal-content {
            & {
                padding: 0;
                -webkit-overflow-scrolling: touch;
            }
            .input-radio {
                & {
                    padding: 16px;
                    position: relative;
                }
                &:not(:last-child) {
                    border-bottom: 1px solid rgba(#000, 0.1);
                }
                label {
                    min-height: 25px;
                    height: auto;
                    display: block;
                    color: color("shades", "black");
                }
                input[type=radio]:disabled + label,
                input[type=checkbox]:disabled + label {
                    color: $input-disabled-color;
                }
                input[type=radio]:checked + label,
                input[type=checkbox]:checked + label {
                    color: $secondary_color;
                }
            }
        }
    }
    .modal-footer {
        .btn-flat {
            padding: 0 16px;
            font-weight: 600;
            font-size: 16px;

            @media #{$small-and-down} {
                font-size: 14px;
            }
            @media only screen and (max-width : 320px) {
                font-size: 11px;
            }
        }
    }
}

#photo-replace-modal {
    border-radius: 8px;
    padding: 0px 15px 10px;

    .modal-title {
        @include proxima-font-semibold();

        text-transform: capitalize;
        font-size: 20px;
    }
    .modal-content {
        @include proxima-font();

        padding: 24px 10px;

        @media screen and (max-width: 325px) {
            padding: 15px 0px 14px;
        }
    }
    @media screen and (min-width: 768px) {
        padding: 0px 10px 15px;
    }
}

#photo-replace-modal {
    .refresh-icon {
        background-image: url('../../../img/photos/manage-photos/refresh-icon.svg');
        height: 72px;
        width: 72px;
        margin: auto;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        display: block;
    }
}

#rejected-photos {
  .rejected-photos-list {
      overflow: auto;
      text-align: left;
      margin-top: 24px;
      margin-bottom: 0;

      li {
          display: flex;
          align-items: center;

          &:not(:last-of-type) {
            margin-bottom: 8px;
          }

          span {
              font-family: 'Proxima Nova Semibold';
              font-size: 16px;
              color: #2F3740;
              flex: 1;
          }
      }
  }
  .rejected-photo {
      width: 72px;
      height: 72px;
      background-size: cover;
      border-radius: 8px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;
  }
}

.modal.modal-v2 {
  top: 50% !important;
  transform: scalex(1) translate(0, -50%) !important;
  transition: top 0.2s, opacity 0.2s;
  border-radius: 8px;
  background: white;
  border-radius: 12px;
  opacity: 1;

  .modal-title {
      color: #2F3740;
      font-size: 20px;
      margin: 8px auto 24px;
  }

  .modal-text {
    color: #2F3740;
    font-size: 18px;
  }

  .modal-footer {
    height: 94px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-top: 1px solid #CAD1D9;
    padding-left: 24px;
    padding-right: 24px;
  }
}
