#support-v2 {
  #content {
    text-align: inherit;
    padding: 0 16px;
    height: calc(100dvh - 48px);
    background-color: white;
    overflow-y: auto;
    padding-bottom: 100px;
  }
  .header {
    padding-top: 5px;
    margin-bottom: 24px;
    text-align: center;
    /* display: flex;
    flex-direction: column;
    align-items: center; */

    .support-v2__title {
      margin: 0;
      margin-bottom: 24px;
      color: #2F3740;
      font-size: 26px;
    }

    .support-v2__description {
      text-align: left;
      color: #2F3740;
      font-size: 18px;
      padding: 0 8px;
    }
  }

  .support-v2__ticket-list__header {
    font-size: 18px;
    font-family: 'Proxima Nova Semibold';
    padding: 0 8px;
  }

  .support-v2__footer {
    width: 100%;
    height: 95px;
    border-top: 1px solid #CAD1D9;
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 24px 31px;
    background-color: white;
    z-index: 10;

    @media screen and (min-width: 600px) {
      width: 600px;
    }
  }

  .support-v2__cta {
    min-width: 128px;
    width: 100%;
    height: 47px;
    padding: 15px 24px 14px;
    border-radius: 10px;
    font-size: 16px;
    outline: none;
    box-shadow: none;
    border: unset;
    text-transform: uppercase;
    font-family: 'Proxima Nova Semibold';
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent linear-gradient(250deg, #FF0073 0%, #DC0064 100%) 0% 0% no-repeat padding-box;
    color: white;
  }

  .closed {
    opacity: 0.6;
  }
}

.support-v2-item {
  padding: 16px;
  box-shadow: 0px 3px 6px #2F374029;
  border-radius: 16px;
  margin-bottom: 12px;

  .support-v2-item__labels {
    display: flex;
    align-items: center;
  }

  .support-v2-item__category {
    background: #2F3740;
    color: white;
    padding: 1px 8px;
    border-radius: 4px;
    font-size: 12px;
  }

  .support-v2-item__ticket-title, .support-v2-item__time-ago {
    color: #2F3740;
    font-size: 14px;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
  }

  .support-v2-item__ticket-title {
    margin: 0;
    margin-left: 8px;
  }

  .support-v2-item__time-ago {
    max-width: 80%;
  }

  .support-v2-item__indicators {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .support-v2-item__dot {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #E30074;
    margin-right: 4px;
  }

  .support-v2-item__preview {
    font-size: 16px;
    color: #2F3740;
    margin-top: 12px;
    display: inline-block;
  }

  &.--unread {
    .support-v2-item__preview, .support-v2-item__ticket-title, .support-v2-item__time-ago {
      font-family: 'Proxima Nova Semibold';
    }
  }
}

#support-v2-request {
  background-color: white;

  .chat-controls {
    margin: auto;
    padding: 17px 24px;
    background-color: white;
    min-height: 95px;
    border-top: 1px solid #CAD1D9;
    max-width: 600px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;

    textarea {
      border: none !important;
      margin-bottom: 0;
      min-height: 1rem;
      max-height: 78px;
      overflow: hidden;
      color: #2F3740;
      width: 100%;
      padding: 0;
      z-index: 5;
      position: relative;
      background: transparent;
      border-radius: unset;

      & + label {
        display: none !important;
      }
    }

    .input-field {
      input {
        background-color: #FFF;
      }
    }

    @media #{$large-and-up} {
      max-width: 600px;
    }

  }
}

#support-v2-request-new {
  .textarea-field textarea.materialize-multiline {
    margin-bottom: 0 !important;
    height: 40px;
    overflow-y: auto;
  }

  .textarea-field label {
    color: #52575B;
    top: 14px;
  }

  .input-field--APV_REG_REDESIGN.disabled {
    background: #f7fafc !important;
  }

  #content {
    min-height: calc(100dvh - 48px);
    background-color: white;
    color: #2F3740;
    padding-bottom: 100px;
    padding-top: 5px;
  }

  .support-request__title {
    font-size: 26px;
    color: #2F3740;
    margin-top: 5px;
    margin-bottom: 32px;
  }

  .support-request__subtitle {
    font-size: 18px;
    color: #2F3740;
    text-align: left;
    margin-top: 0;
    margin-bottom: 24px;
  }

  .md-select--NEW_APV_REG_REDESIGN {
    width: 100%;
  }

  .support-request__files {
    margin-top: 8px;
  }
  .support-request__file-item {
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: 24px;
    padding: 0 16px;
    text-align: left;
    position: relative;
    margin-bottom: 8px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .3;
      border-radius: 24px;
      background-color: #CED6E0;
      z-index: 0;
    }

    .file-info {
      flex: 1;
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 5px;
    }

    .clickable {
      color: #78838f;
      z-index: 5;
    }
  }

  .support-request__footer {
    width: 100%;
    height: 95px;
    border-top: 1px solid #CAD1D9;
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 24px 31px;
    background-color: white;
    z-index: 10;

    @media screen and (min-width: 600px) {
      width: 600px;
    }
  }

  .support-request__attach-cta {
    display: flex;
    align-items: center;
  }

  .support-request__attach {
    color: #2F3740;
    font-family: 'Proxima Nova Semibold';
    font-size: 16px;
    text-decoration: underline;
    text-transform: uppercase;
    background: transparent;
    margin-left: 3px;
  }

  .support-request__cta {
    min-width: 128px;
    height: 47px;
    padding: 15px 24px 14px;
    border-radius: 10px;
    font-size: 16px;
    outline: none;
    box-shadow: none;
    border: unset;
    text-transform: uppercase;
    font-family: 'Proxima Nova Semibold';
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent linear-gradient(250deg, #FF0073 0%, #DC0064 100%) 0% 0% no-repeat padding-box;
    color: white;

    &:disabled {
      background: #CED6E0;
    }
  }

  .char-count {
    text-align: right;
    margin-right: -16px;
    color: #52575B;
    font-size: 12px;
    font-family: 'Proxima Nova Medium';
  }
}

.support-v2-request__ticket-title {
  margin-left: 3px;
  color: #2F3740;
  font-size: 16px;
  font-family: 'Proxima Nova Semibold';
}

#support-v2-request-chat {
  &.ticket-closed {
    #chat-bubble {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 0.6;
    }
    #content {
      min-height: calc(100vh - 130px);
    }
  }

  .category {
    background: color("grey", "base");
    color: color("shades", "white");
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 13px;
  }

  form {
    height: 10px;
    position: relative;
  }

  .attach-file {
    margin-right: 7px;
  }

  .btn-submit {
    position: absolute;
    right: 36px;
    height: 26px;
    width: 26px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2F3740;
    color: white !important;
    z-index: 10;

    i {
      font-size: 16px;
    }

    &[disabled] {
      cursor: not-allowed;
    }

    &.btn-submit--disabled {
      background-color: #CED6E0;
    }
  }

  #content {
    background: white;
    padding: 72px 24px 150px;
    min-height: calc(100dvh - 95px);
    overflow-y: auto;

    &.--with-bottom-status-indicator {
      margin-bottom: 95px;
    }
  }
}

.chat-bubble-support {
  color: $krypton-grey-darken;
  position: relative;
  padding: 0;
  min-height: 100px;

  .file-info {
      padding-bottom: 0;
      padding-top: 0;
  }
  .status {
      display: block;
      width: 90%;
      margin-top: 8px;
      margin-bottom: 16px;
      text-align: left;
      line-height: 11px;
  }
  .text {
      color: #2F3740;
      padding: 16px;
      border-radius: 4px;
      word-break: break-word;
      text-align: left;
  }
  .text-message {
    position: relative;
    z-index: 1;
  }
  .bubble-texts {
    width: 100%;
  }
  &.reverse {
      .text {
        background: transparent;
        border: 1px solid #CED6E0;
        border-radius: 24px 24px 24px 0;

      }

      .bubble-texts {
        order: 2;
      }

      .chat-bubble__container {
        display: flex;
        align-items: self-start;
        justify-content: flex-start;
      }

      .chat-bubble__container--self {
        justify-content: flex-end;
      }

      .bubble-icon {
        order: 1;
        margin-right: 6px;
        margin-top: 12px;
      }

      &.me {
          .text {
            border-radius: 24px 24px 0 24px;
            position: relative;
            border: none;

            &::after {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: #CED6E0;
              border-radius: 24px 24px 0 24px;
              opacity: 0.3;
              z-index: 0;
            }
          }

          .bubble-texts {
            order: 1;
          }

          .bubble-icon {
            margin-left: 6px;
            margin-top: 12px;
            order: 2
          }
      }
  }
  .time-ago {
      color: #8995A3;
      font-size: 11px;
      margin-left: 16px;
  }
  .file-upload {
    z-index: 1;
    position: relative;
  }
}
