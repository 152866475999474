#dashboard {
  #content.container {
    background-color: white;
    border-radius: 32px 32px 0px 0px;
    margin-top: -80px;

    @media screen and (min-width: 600px) {
      width: 600px;
    }
  }

  .section-label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    img {
      margin-right: 12px;
      padding-bottom: 6px;
    }

    h2 {
      font-size: 20px;
      color: #2E353D;
      margin: 0;
    }
  }

  @media screen and (max-width: 767px) {
    #news-list,
    #performance-overview,
    .overview-table {
      max-width: 312px;
      margin: auto;
    }
  }

  // Overview Table
  .overview-table {
    border: 1px solid #C4C4C4;
    padding: 17px 5px 55px;
    border-radius: 4px;
    position: relative;

    @media screen and (min-width: 360px) {
      padding: 17px 17px 55px;
    }
    @media screen and (min-width: 768px) {
      padding: 17px 138px 55px;
    }
    .overview-table--title,
    .overview-table--schedule {
      display: grid;
      grid-template-columns: repeat(7, 32px);
      grid-gap: 8px;
      justify-content: center;

      @media screen and (max-width: 320px) {
        grid-gap: 6px;
      }
    }
    .overview-table--block {
      height: 32px;
      width: 32px;
      border-radius: 4px;
      position: relative;
    }
    .overview-table--schedule {
      .overview-table--block {
        background: #E0E0E0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        &.filled {
          background-image: url('../../img/availability/ic-status-added.svg');
          background-color: #00e676;
        }
        &.unfilled {
          background-image: url('../../img/availability/ic-status-add.svg');
          background-color: #c51162;
        }
        &.unavailable {
          background-image: url('../../img/availability/ic-status-away.svg');
          background-color: #e0e0e0;
        }
        &.past {
          background-image: url('../../img/availability/ic-status-past.svg');
          background-color: #e0e0e0;
        }
      }
    }
    .overview-table--title {
      .overview-table--block {
        font-size: 11px;
        height: 22px;
      }
    }
  }
  .overview-indicator {
    width: 32px;
    height: 32px;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
    background-size: contain;
    background-repeat: no-repeat;

    &.sad {
      background-image: url('../../img/availability/sad-icon.svg');
    }
    &.neutral {
      background-image: url('../../img/availability/neutral-icon.svg');
    }
    &.happy {
      background-image: url('../../img/availability/happy-icon.svg');
    }
  }
}
