nav {
    .right {
        height: 56px;
    }
    .center-countdown {
        margin-right: -65px;

        @media screen and (min-width: 321px) and (max-width: 375px) {
          margin-right: -90px;
        }
    }
    .coundown-holder {
      padding-right: 16px;

      @media #{$extra-small-and-down} {
        padding-right: unset;
      }
    }
    .countdown {
        padding: 4px 8px;
        border-radius: 4px;
        background: #000;
        color: color("shades", "white");
        font-size: 13px;

        @media #{$extra-small-and-down} {
            margin-left: 55px;
        }

        @media #{$small-and-down} {
            font-size: 12px;
        }
    }
    .brand-logo {
        & {
            text-transform: uppercase;
            padding: 0 24px;
            white-space: nowrap;
            left: 0;
        }

        @media #{$medium-and-down} {
            left: 0;
            transform: translateX(0);
        }

        i,
        [class^="mdi-"], [class*="mdi-"],
        i.material-icons {
          margin-right: 4px;
        }
    }
    ul {
        a {
            padding: 0 24px;
        }
    }
}

#robot-icon {
  background-image: url('../../../img/photos/manage-photos/robot-icon.svg');
  height: 48px;
  width: 28px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: block;
  cursor: pointer;

  &.white-icon {
      background-image: url('../../../img/photos/manage-photos/robot-icon.white.svg');
  }
}

.navbar-fixed {
  padding: 0 12px;
  height: 48px !important;

  nav {
    height: 48px;
    line-height: 48px !important;
    width: 100%;
    left: 0;

    .brand-logo {
      left: 0;
      transform: translateX(12px);
    }

    .right {
      height: 48px;
    }

    .nav-wrapper {
      padding: 0 12px;
    }
  }
}
