#status-indicator-old {
  position: relative;
  height: 61px;
  font-size: 14px;
  font-weight: 700;
  font-family: "Proxima Nova Semibold";

  .proper-font {
    color: #2F3740 !important; float: left; font-family: "Proxima Nova"; font-size: 14px !important; font-weight: 400; line-height: 17px; margin-top: 3px !important;
    }

  .billing-btn {
    color: #2F3740 !important; cursor: pointer; float: right; font-size: 16px !important; line-height: 14px; margin-top: 6px !important;
  }

  @media #{$medium-and-down} {
    font-size: 13px;
  }
  @media #{$extra-small-and-down} {
    font-size: 10px;
  }

  .new-container {
    height: 100%;
    padding: 0 20px;
    margin: 0 auto;
    max-width: 1280px;

    @media #{$medium-and-down} {
      padding: 0;
      width: 100%;
    }
  }

  .container {
    height: 100%;

    p {
      margin: 0;
    }
  }
  .need-verification {
    .nav-content {
      color: color("krypton-grey", "darken");
      padding: 16px;
      height: 56px;

      .container {
        display: flex;
        align-items: center;
      }

      .new-container {
        width: 600px;
        position: relative;
        display: block;

        @media (max-width: 420px) { display: contents; }
        @media #{$extra-small-and-down} { display: flex; align-items: center; }
      }

      p {
        font-size: 14px;
      }
      a {
        color: color("krypton-grey", "darken");
        font-size: 14px;
      }

      @media #{$small-and-down} {
        p {
          font-size: 12px;
        }
        a {
          font-size: 12px;
        }
      }
      @media #{$extra-small-and-down} {
        p {
          font-size: 11px;
        }
        a {
          font-size: 11px;
        }
      }

    }
  }
  .unread-message {
    padding: 0 7px;
    font-weight: bold;
    font-size: 10px;
    border-radius: 99%;
    background: #c51162;
    line-height: 18px;
  }
  .pre-nav-content {
    position: fixed;
    padding: 16px 0;
    z-index: 990;
    width: 100%;

    @media #{$medium-and-down} {
      padding: 16px 8px;
    }
    background: #00D96F;
    color: color("shades", "white");

    &.cancellation-request,
    &.pending {
      background: #C51162;
    }
    &.cancellation-request {
      padding: 16px 24px;
    }
    &.vip-photo-banner {
      background: #2f3740;

      @media #{$medium-and-down} {
        .valign-middle {
          font-size: 14px;
          padding-left: 24px;
        }
        .vip-photo-banner-cta {
          padding-right:24px;
          padding-left:0px;
        }
      }

    }
    &.vip-photo-banner-cta {
      padding: 4px 9px;
      color: #FFFFFF;
      border-radius: 4px;
      letter-spacing: 0px;
      margin-top: -5px;
    }
  }
  .nav-content {
    position: fixed;
    padding: 16px 0;
    z-index: 990;
    width: 100%;

    @media #{$medium-and-down} {
      padding: 16px 8px;
    }
    background: color("krypton-green", "base");
    color: color("shades", "white");

    &.cancellation-request,
    &.pending {
      background: #C51162;
    }
    &.cancellation-request {
      padding: 16px 24px;
    }
  }
  .timer,
  .container {
    line-height: 26px;
  }
  .material-icons {
    display: inline-block;
    height: 26px;
    line-height: 26px;
  }
  .timer {
    background: color("shades", "black");
    padding: 4px 9px;
    color: color("shades", "white");
    border-radius: 4px;
    letter-spacing: 0px;
    margin-top: -5px;

    @media #{$extra-small-and-down} {
      font-size: 11px;
    }
  }
}

#status-indicator {
  position: relative;
  height: 56px;
  font-size: 14px;
  font-weight: 700;
  font-family: "Proxima Nova Semibold";

  .proper-font {
    color: #2F3740 !important; float: left; font-family: "Proxima Nova"; font-size: 14px !important; font-weight: 400; line-height: 17px; margin-top: 3px !important;
    }

  .billing-btn {
    color: #2F3740 !important; cursor: pointer; float: right; font-size: 16px !important; line-height: 14px; margin-top: 6px !important;
  }

  @media #{$medium-and-down} {
    font-size: 13px;
  }
  @media #{$extra-small-and-down} {
    font-size: 10px;
  }

  .new-container {
    height: 100%;
    padding: 0 20px;
    margin: 0 auto;
    max-width: 1280px;

    @media #{$medium-and-down} {
      padding: 0;
      width: 100%;
    }
  }

  .container {
    height: 100%;

    p {
      margin: 0;
    }
  }

  .need-verification {
    .nav-content {
      color: color("krypton-grey", "darken");
      padding: 16px;
      height: 56px;

      .container {
        display: flex;
        align-items: center;
      }

      .new-container {
        width: 600px;
        position: relative;
        display: block;

        @media (max-width: 420px) { display: contents; }
        @media #{$extra-small-and-down} { display: flex; align-items: center; }
      }

      p {
        font-size: 14px;
        line-height: 16px;
      }

      a {
        color: color("krypton-grey", "darken");
        font-size: 14px;
        line-height: 18px;
      }

      .verify-cta {
        font-size: 16px;
        text-decoration: underline;
        color: #2F3740;
        font-family: "Proxima Nova Semibold";
        width: 100%;
        text-align: center;
      }

      @media #{$small-and-down} {
        p {
          font-size: 12px;
        }
        a {
          font-size: 12px;
        }
      }
      @media #{$extra-small-and-down} {
        p {
          font-size: 11px;
        }
        a {
          font-size: 11px;
        }
      }
    }
  }

  .unread-message {
    padding: 0 7px;
    font-weight: bold;
    font-size: 10px;
    border-radius: 99%;
    background: #c51162;
    line-height: 18px;
  }

  .pre-nav-content {
    width: 100%;
    background: #00D96F;
    color: color("shades", "white");

    @media #{$medium-and-down} {
      padding: 16px 8px;
    }

    &.cancellation-request,
    &.pending {
      background: #C51162;
    }
    &.cancellation-request {
      padding: 16px 24px;
    }
    &.vip-photo-banner {
      background: #2f3740;

      @media #{$medium-and-down} {
        .valign-middle {
          font-size: 14px;
          padding-left: 24px;
        }
        .vip-photo-banner-cta {
          padding-right:24px;
          padding-left:0px;
        }
      }

    }
    &.vip-photo-banner-cta {
      padding: 4px 9px;
      color: #FFFFFF;
      border-radius: 4px;
      letter-spacing: 0px;
      margin-top: -5px;
    }
  }

  .nav-content {
    position: fixed;
    padding: 16px 0;
    z-index: 990;
    width: 100%;

    @media #{$medium-and-down} {
      padding: 16px 8px;
    }
    background: color("krypton-green", "base");
    color: color("shades", "white");

    &.cancellation-request,
    &.pending {
      background: #C51162;
    }
    &.cancellation-request {
      padding: 16px 24px;
    }
  }

  .timer,
  .container {
    line-height: 26px;
  }

  .material-icons {
    display: inline-block;
    height: 26px;
    line-height: 26px;
  }

  .timer {
    background: color("shades", "black");
    padding: 4px 9px;
    color: color("shades", "white");
    border-radius: 4px;
    letter-spacing: 0px;
    margin-top: -5px;

    @media #{$extra-small-and-down} {
      font-size: 11px;
    }
  }

  // The update for having the banner be fixed to bottom would be just
  // for id verification banner for now
  // Moving forward, we can have the --bottom and/or specifc
  // classes be available to all other status indicator
  &.status-indicator--id-verification {
    .need-verification {
      .yellow.warning-base {
        background-color: #FFD600 !important;
      }

      .verification-pending {
        font-size: 16px;
        width: 100%;
        text-align: center;
      }
    }

    &.status-indicator--bottom  {
      width: 100%;
      height: 79px;
      line-height: 16px !important;
      position: fixed;
      -ms-transform: translate3d(0,0,0);
      -webkit-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
      z-index: 995;
      bottom: 0;

      .need-verification .nav-content, .event-status .pre-nav-content {
        padding: 0;
        height: 79px;

        .container {
          width: 100%;
          padding: 16px 24px 0;
          max-width: 600px;
          display: flex;
          align-items: flex-start;
        }

        .container .active-event {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
      }

      &.status-indicator--with-fixed-menu {
        height: 48px;
        bottom: 88px;

        .need-verification .nav-content, .event-status .pre-nav-content {
          height: 48px;

          .container {
            padding: 0 24px;
            align-items: center;
          }
        }
      }

      &.status-indicator--with-fixed-navigator{
        height: 48px;
        bottom: 95px;

        .need-verification .nav-content, .event-status .pre-nav-content {
          height: 48px;

          .container {
            padding: 0 24px;
            align-items: center;
          }
        }
      }

      &.status-indicator--with-fixed-footer-buttons {
        height: 48px;
        bottom: 96px;

        .need-verification .nav-content, .event-status .pre-nav-content {
          height: 48px;

          .container {
            padding: 0 24px;
            align-items: center;
          }
        }
      }

      &.status-indicator--with-fixed-chat-controls{
        height: 48px;
        bottom: 80px;

        .need-verification .nav-content, .event-status .pre-nav-content {
          height: 48px;

          .container {
            padding: 0 24px;
            align-items: center;
          }
        }
      }
    }
  }
}

