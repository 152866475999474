// Border width mixin
@mixin border($top, $right, $bottom, $left) {
    border-style: solid;
    border-width: $top $right $bottom $left;
}

// Generate base border width and style
@each $side in $sides {
    @if $side == "all" {
        .border {
            @include border(1px, 1px, 1px, 1px);
        }
    }
    @else if $side == "x" {
        .border-x {
            @include border(0, 1px, 0, 1px);
        }
    }
    @else if $side == "y" {
        .border-y {
            @include border(0, 1px, 0, 1px);
        }
    }
    @else {
        .border-#{$side} {
            border-style: solid;
            border-width: 0px;
            border-#{$side}-width: 1px;
        }
    }
}

// Generate border width and style for different breakpoints
@each $breakpoint_name, $breakpoint in $breakpoints {
    @each $side in $sides {
        @if $breakpoint_name != "sm" {
            @media (min-width: $breakpoint) {
                @if $side == "all" {
                    .border-#{$breakpoint_name} {
                        @include border(1px, 1px, 1px, 1px);
                    }
                }
                @else if $side == "x" {
                    .border-x-#{$breakpoint_name} {
                        @include border(0, 1px, 0, 1px);
                    }
                }
                @else if $side == "y" {
                    .border-y-#{$breakpoint_name} {
                        @include border(0, 1px, 0, 1px);
                    }
                }
                @else {
                    .border-#{$side}-#{$breakpoint_name} {
                        border-#{side}-width: 1px;
                    }
                }
            }
        }
    }
}

// Generate border colors
@each $color_name, $color in $colors {
    @each $color_type, $color_value in $color {
        @if $color_type == "base" {
            .border-#{$color_name} {
                border-color: $color_value;
            }
        }
        @else {
            .border-#{$color_name}-#{$color_type} {
                border-color: $color_value;
            }
        }
    }
}

// BORDER RADIUS
.border-rad-xs {
    border-radius: 2px;
}
.border-rad-sm {
    border-radius: 4px;
}
.border-rad-md {
    border-radius: 8px;
}
.border-rad-lg {
    border-radius: 16px;
}
.border-rad-xl {
    border-radius: 24px;
}
