// textfield boxes
.input-field.textfield-box,
.input-field.textfield-box > .select-wrapper {
    & {
        margin-top: 0;
    }
    & > input,
    & > textarea {
        border-radius: 4px;
        box-sizing: border-box;
        padding-left: 16px;
        padding-right: 16px;
        height: auto;
        line-height: initial;
        padding-bottom: 6px;
        padding-top: 22px;
    }
    & > textarea.materialize-multiline {
        height: 48px;
    }
    & > label {
        transform-origin: 0 0;
    }
    & > label:not(.label-icon).active {
        transform: translateY(3px) translateX(2px) scale(0.8);
    }
}
.input-field.textfield-box.textfield-box-lg,
.input-field.textfield-box.textfield-box-lg > .select-wrapper {
    & > input,
    & > textarea {
        padding-bottom: 8px;
        padding-top: 28px;
        font-size: 16px;
    }
    & > textarea.materialize-multiline {
        height: 56px;
    }
    & > label {
        transform: translateY(18px);
    }
    & > label:not(.label-icon).active {
        transform: translateY(5px) translateX(2px) scale(0.8);
    }
}

// Special specs for select textfield boxes
.input-field.textfield-box > .select-wrapper {
    & > input {
        padding-right: 32px;
    }
    & > .caret {
        right: 12px;
    }
    & + label {
        top: 0;
        font-size: 1rem;
        transform: translateY(3px) translateX(2px) scale(0.8);
    }
}
.input-field.textfield-box.textfield-box-lg > .select-wrapper {
    & + label {
        transform: translateY(5px) translateX(2px) scale(0.8);
    }
}
.input-field.textfield-label-sm {
    & > label {
        font-size: 0.8rem;
    }
}
.textfield-h-100,
.input-field.textfield-box.textfield-h-100 {
    & > textarea.materialize-multiline {
        height: 100px;
    }
}
