#install-chrome-banner {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  text-align: center;
  color: #959DA6;
  padding: 23vh 5vh 0;
  box-sizing: border-box;
  background-color: #000;
  z-index: 9999;
  background-color: #F7FAFC;
  display: flex;
  align-content: center;

  .app-store-btn {
    background: #2F3740;
    color: #FFFFFF;
    border-radius: 4px;
    height: 56px;
    padding-top: 10px;
    padding-bottom: 19px;
  }
  .title {
    .app-icon,
    .app-details {
      display: inline-block;
      vertical-align: middle;
    }
    .app-icon {
      width: 120px;
      height: 120px;
      margin-bottom: 40px;
    }
  }
}
