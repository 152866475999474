#video-date-landing-page {
  @media screen and (min-width: 321px) {
    .btn-krypton-purple {
      font-size: 14px !important;
    }
  }
  .btn-krypton-purple {
    max-width: 345px;
  }
  .explanation,
  .incentives,
  .how-it-works,
  .q-and-a,
  .activate-section {
    max-width: 600px;
    margin: auto;
  }
  .explanation {
    position: relative;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255,255,255,0), rgba(255,255,255,0), rgba(255,255,255,0.4), rgba(255,255,255,1), rgba(255,255,255,1), rgba(255,255,255,1), rgba(255,255,255,1), rgba(255,255,255,1), rgba(255,255,255,1)), url("/img/video-date/video_date_promo.png");
    background-repeat: no-repeat;
    background-position: top;
    background-position: 0px -70px;
    background-size: contain;

    @media screen and (max-width: 325px) {
      background: linear-gradient(rgba(255, 255, 255, 0), rgba(255,255,255,0), rgba(255,255,255,1), rgba(255,255,255,1), rgba(255,255,255,1), rgba(255,255,255,1), rgba(255,255,255,1)), url("/img/video-date/video_date_promo.png");
      background-position: 0 -55px;
      background-repeat: no-repeat;
      background-size: contain;
    }
    @media screen and (min-width: 768px) {
      background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 254, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1)), url("/img/video-date/video_date_promo.png");
      background-position: 0 -110px;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .img-background {
      position: absolute;
      width: 100%;
      left: 0;
      z-index: 1;

      &::after {
        content: "";
        height: 100px;
        position: absolute;
        bottom: 0;
        background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
      }
    }
    .content {
      z-index: 100;
      position: relative;
      padding-top: 131px;
    }
  }
  .heading {
    font-size: 38px;
    margin-top: 0;

    @media screen and (min-width: 768px) {
      margin-top: 60px;
    }
  }
  .item {
    .title {
      font-size: 26px;
    }
  }
  .description,
  .how-it-works-text {
    color: #737B85;
  }
  .description {
    font-size: 18px;
  }
  .activate-section,
  .how-it-works {
    background: #F7FAFC;

    .img-wrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
      max-width: 312px;
      height: 312px;
      margin: 24px auto 0;

      img {
        position: absolute;
        left: -75px;
        right: 0;
        top: 0;
        bottom: 0;
        width: auto;
        height: auto;
        margin: auto;
      }
    }
  }
    .apv-collapsible {
        border: none;
        box-shadow: none;

        li {
          border-bottom: 1px solid #CAD1D9;
          padding-bottom: 0;
          margin: 10px 0;

          .title {
            font-size: 16px;
            margin-bottom: 8px;
          }
          .description {
            font-size: 14px;
          }
        }
        .collapsible-header {
            background: transparent;
            border: none;
            padding-bottom: 14px;
            font-size: 26px;
            position: relative;
            text-align: left;

            &::after {
                content: "keyboard_arrow_up";
                font-family: "Material Icons";
                font-feature-settings: "liga" 1;
                position: absolute;
                font-size: 24px;
                left: 0;
                top: 6px;
                color: #C51162;
                border-radius: 99%;
                width: 30px;
                transition: transform 0.3s;
                text-align: center;
                transform: rotateZ(90deg);
            }
            &.active::after {
                transform: rotateZ(0deg);
            }
            p {
                word-break: break-word;
                padding-right: 45px;
                margin: 0;
            }
        }
        .collapsible-body {
            font-size: 18px;
            display: block;
            text-align: left;
            padding: 0;
            border: none;
            color: #737B85;

            p {
              margin-bottom: 0;
              margin-top: 0;
            }
        }
    }
    .btn-large {
      height: 64px;
    }
}
