body #account
{
    background-color: #f7fafc;
}

#account {
    .title {
        @include proxima-font-semibold();

        margin: 0 0 16px;
        font-size: 26px;
        text-transform: initial;
    }
}
