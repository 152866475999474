$sizes: (
    "none": 0px,
    "xs":   4px,
    "sm":   8px,
    "md":   16px,
    "lg":   24px,
    "xl":   32px,
    "x2":   40px,
    "x3":   48px,
    "x4":   56px,
    "x5":   64px,
    "x6":   72px,
);

@each $breakpoint_name, $breakpoint in $breakpoints {
    @each $side in $sides {
        @each $size_name, $size in $sizes {
            @if $breakpoint_name == "sm" {
                @if $side == "all" {
                    .margin-#{$size_name} {
                        margin: $size;
                    }
                    .padding-#{$size_name} {
                        padding: $size;
                    }
                }
                @else if $side == "y" {
                    .margin-#{$side}-#{$size_name} {
                        margin-top: $size;
                        margin-bottom: $size;
                    }
                    .padding-#{$side}-#{$size_name} {
                        padding-top: $size;
                        padding-bottom: $size;
                    }
                }
                @else if $side == "x" {
                    .margin-#{$side}-#{$size_name} {
                        margin-left: $size;
                        margin-right: $size;
                    }
                    .padding-#{$side}-#{$size_name} {
                        padding-left: $size;
                        padding-right: $size;
                    }
                }
                @else {
                    .margin-#{$side}-#{$size_name} {
                        margin-#{$side}: $size;
                    }
                    .padding-#{$side}-#{$size_name} {
                        padding-#{$side}: $size;
                    }
                }
            }
            @else {
                @if $side == "all" {
                    @media (min-width: $breakpoint) {
                        .#{$breakpoint_name}-margin-#{$size_name} {
                            margin: $size;
                        }
                        .#{$breakpoint_name}-padding-#{$size_name} {
                            padding: $size;
                        }
                    }
                }
                @else if $side == "y" {
                    @media (min-width: $breakpoint) {
                        .#{$breakpoint_name}-margin-#{$side}-#{$size_name} {
                            margin-top: $size;
                            margin-bottom: $size;
                        }
                        .#{$breakpoint_name}-padding-#{$side}-#{$size_name} {
                            padding-top: $size;
                            padding-bottom: $size;
                        }
                    }
                }
                @else if $side == "x" {
                    @media (min-width: $breakpoint) {
                        .#{$breakpoint_name}-margin-#{$side}-#{$size_name} {
                            margin-left: $size;
                            margin-right: $size;
                        }
                        .#{$breakpoint_name}-padding-#{$side}-#{$size_name} {
                            padding-left: $size;
                            padding-right: $size;
                        }
                    }
                }
                @else {
                    @media (min-width: $breakpoint) {
                        .#{$breakpoint_name}-margin-#{$side}-#{$size_name} {
                            margin-#{$side}: $size;
                        }
                        .#{$breakpoint_name}-padding-#{$side}-#{$size_name} {
                            padding-#{$side}: $size;
                        }
                    }
                }
            }
        }
    }
}
