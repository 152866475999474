
// Krypton Materialize Overrides

html {
  color: #2E353D;
}

// Navbar
// ==========================================

.navbar-fixed {
  z-index: 990;
}

.md-select {
  i.material-icons {
    pointer-events: none;
    position: absolute;
    top: 26px;
    right: 7px;
  }
}

// Input Fields
// ==========================================
textarea.materialize-multiline {
  padding: 1rem 0 0.75rem;
}

input[type=range] {
  border: none;
}

.input-field label:not(.label-icon).active {
  -webkit-transform: translateY(0px) scale(0.9);
  transform: translateY(0px) scale(0.9);
  left: 16px;
  top: 4px;
}

.input-field--NEW_APV_REG_REDESIGN {
  margin-top: 0;

  &.has-placeholder {
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    input[type="text"] {
      padding: 0 0 0 14px;
    }
  }

  &.active {
    label {
      -webkit-transform: translateY(0px) scale(0.9);
      transform: translateY(0px) scale(0.9);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      top: 8px;
    }
  }

  &.--disabled {
    input {
      background: #f7fafc;
    }
  }

  label {
    // color: $krypton-grey-lighten;
    // left: 14px;
    // top: 14px;
    // height: 50%;
  }
  .select-wrapper + label {
    // top: -5px;
    // left: 13px;
  }
  .select-wrapper {
    span.caret {
      // display: none;
      // color: #8A8F95;
      // z-index: 100;
      // right: 15px;
    }
  }
  textarea.materialize-multiline:focus:not([readonly]),
  input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 2px solid $krypton-grey-darken;
    box-shadow: none;
  }
  textarea.materialize-multiline {
    border-radius: 0;
    border-bottom: 2px solid $krypton-grey-darken;
    padding: 29px 14px 10px;
    width: calc(98% - 22px);
  }
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  input[type="text"]:not(.select-dropdown) {
    color: $krypton-grey-darken;
    padding: 15px 0 0 14px;
    border-bottom: 2px solid $krypton-grey-darken;
    border-radius: 2px;
    width: calc(98% - 8px);
    background: #FFF;
    height: 42px;
  }
}

.input-field {
  margin-top: 0;

  &.has-placeholder {
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    input[type="text"] {
      padding: 0 0 0 14px;
    }
  }
  &.active {
    label {
      -webkit-transform: translateY(0px) scale(0.9);
      transform: translateY(0px) scale(0.9);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      top: 8px;
    }
  }
  label {
    color: $krypton-grey-lighten;
    left: 14px;
    top: 14px;
    height: 50%;
  }
  .select-wrapper + label {
    top: -5px;
    left: 13px;
  }
  .select-wrapper {
    span.caret {
      display: none;
      color: #8A8F95;
      z-index: 100;
      right: 15px;
    }
  }
  textarea.materialize-multiline:focus:not([readonly]),
  input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 2px solid $krypton-grey-darken;
    box-shadow: none;
  }
  textarea.materialize-multiline {
    border-radius: 0;
    border-bottom: 2px solid $krypton-grey-darken;
    padding: 29px 14px 10px;
    width: calc(98% - 22px);
  }
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  input[type="text"] {
    color: $krypton-grey-darken;
    padding: 15px 0 0 14px;
    border-bottom: 2px solid $krypton-grey-darken;
    border-radius: 2px;
    width: calc(98% - 8px);
    background: #FFF;
    height: 42px;
  }
}


textarea.materialize-multiline:focus:not([readonly]) + label,
input:not([type]):focus:not([readonly]) + label,
input[type=text]:not(.browser-default):focus:not([readonly]) + label,
input[type=password]:not(.browser-default):focus:not([readonly]) + label,
input[type=email]:not(.browser-default):focus:not([readonly]) + label,
input[type=url]:not(.browser-default):focus:not([readonly]) + label,
input[type=time]:not(.browser-default):focus:not([readonly]) + label,
input[type=date]:not(.browser-default):focus:not([readonly]) + label,
input[type=datetime]:not(.browser-default):focus:not([readonly]) + label,
input[type=datetime-local]:not(.browser-default):focus:not([readonly]) + label,
input[type=tel]:not(.browser-default):focus:not([readonly]) + label,
input[type=number]:not(.browser-default):focus:not([readonly]) + label,
input[type=search]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
  color: $krypton-grey-lighten;
}
